import React from 'react';
import * as i18n from 'i18next';
import { reduxForm } from 'redux-form';
import { Button, Col, Container } from 'reactstrap';
import { FORMS, RELATIVE_PAGE_PATHS } from '../../utils/constants';
import { formUtils } from '../../utils/formUtils';
import { connect } from 'react-redux';
import { Fields } from '../common/Fields';
import navigationMethods from '../../utils/navigationUtils';
import { Trans } from 'react-i18next'
import {
    showDeleteModal,
    closeModal,
    showInfoModal,
    showConfirmModal,
    showRedConfirmModal,
    setJournalTokenActiveStatusToFalse,
    setJournalTokenActiveStatusToTrue
} from '../../actions';

import {
    addContact,
    getContact,
    getContacts,
    updateContact,
    deleteContact,
    handleStopSharingJournalContact,
    shareJournalThroughEmailContact,
    handleShareJournalClickContact,
} from '../../actions/contactActions';

class ContactsDetailScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isJournalShared: null,
            emailMessageShown: false,
            loading: true
        };
    }

    async componentDidMount() {
        const id = this.props.match.params.id;
        if (id) {
            if (!this.props.contacts.length) {
                await this.props.getContacts();
            }
            await this.props.getContact(id);
            this.setState({
                isJournalShared: this.props.currentContact.isJournalShared,
                loading: false
            });
        } else {
            this.setState({ loading: false });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentContact !== prevProps.currentContact) {
            this.setState({
                isJournalShared: this.props.currentContact.isJournalShared,
            });
        }
    }

    handleSubmit = (formValues) => {
        const id = this.props.match.params.id;
        if (id) {
            this.props.updateContact(id, formValues);
        } else {
            this.props.addContact(formValues);
        }
    };

    onDelete = () => {
        const id = this.props.match.params.id;
        const message = i18n.t('contactsScreen:confirmDelete');
        this.props.showDeleteModal({
            message,
            onDelete: () => {
                this.props.deleteContact(id);
                this.props.closeModal();
            },
        });
    };

    renderDeleteButton = () => {
        if (this.props.match.params.id) {
            return (
                <Button
                    onClick={this.onDelete}
                    className="greenBtn header next uppercase secondary danger mt-3"
                >
                    {i18n.t('buttons:delete')}
                </Button>
            );
        }
    };

    renderShareButton = () => {
        const { currentContact } = this.props;
        const id = this.props.match.params.id;
        const isJournalShared = this.state.isJournalShared;
        const shareButton = i18n.t('contactsScreen:buttons:shareJournal');
        const stopSharingButton = i18n.t('contactsScreen:buttons:stopsharingJournal');

        if (this.props.match.params.id) {
            if (!currentContact.email && !this.state.emailMessageShown) {
                this.props.showInfoModal(
                    i18n.t('contactsScreen:addEmail')
                );
                this.setState({ emailMessageShown: true });
                return null;
            }

            const buttonText = isJournalShared ? stopSharingButton : shareButton;
            const buttonClass = isJournalShared ? 'redBtn header next uppercase mt-3' : 'greenBtn header next uppercase mt-3';

            if (id && currentContact.email) {
                return (
                    <Button
                        onClick={() => {
                            const message = isJournalShared
                                ? i18n.t('contactsScreen:confirmStopSharingJournal')
                                : i18n.t('contactsScreen:confirmShareJournal');
                            if (!isJournalShared) {
                                this.props.showConfirmModal({
                                    message,
                                    onConfirm: () => {
                                        this.props.shareJournalThroughEmailContact(currentContact.email);
                                        this.props.handleShareJournalClickContact(this.props.match.params.id);
                                        this.setState({ isJournalShared: true });
                                    }
                                });
                            } else {
                                this.props.showRedConfirmModal({
                                    message,
                                    onConfirm: () => {
                                        this.props.setJournalTokenActiveStatusToFalse(this.props.currentContact.email);
                                        this.props.handleStopSharingJournalContact(this.props.match.params.id);
                                        this.setState({ isJournalShared: false });
                                    }
                                });
                            }
                        }}
                        className={buttonClass}
                    >
                        {buttonText}
                    </Button>
                );
            }
            return null;
        }
    };

    render() {
        const id = parseInt(this.props.match.params.id);
        const contactNumber = this.props.contacts.findIndex((contact) => contact && contact.id === id) + 1;

        const isNewContact = navigationMethods.getRoute() === RELATIVE_PAGE_PATHS.ADD_CONTACT;
        const title = isNewContact
            ? i18n.t('contactsScreen:buttons:addContact')
            : i18n.t('contactsScreen:contact', {
                word1: contactNumber,
            });
        const buttonName = isNewContact
            ? i18n.t('contactsScreen:buttons.add')
            : i18n.t('buttons:saveChanges');

        if (this.state.loading) {
            return <div></div>;
        }

        return (
            <Container className="noPadding">
                <Col
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className="centred noPadding"
                >
                    <form
                        onSubmit={this.props.handleSubmit(this.handleSubmit)}
                        className="flexContainerColumn flexContainerCentered profile-form fix-padding"
                    >
                        <div className="flexContainerCentered">
                            <p className="header mainGreenColor header-small uppercase">
                                {title}
                            </p>
                        </div>

                        <div className="flexContainerColumn flexContainerCentered mt-3">
                            {Fields.contacts.name()}
                            <div className="mt-4">
                                {Fields.commonFields.email()}
                                {Fields.contacts.phone()}
                                {Fields.contacts.address({
                                    className: 'contacts-address input-container',
                                    rows: 10,
                                })}
                            </div>
                        </div>
                        <Col
                            xs={10}
                            sm={10}
                            md={10}
                            lg={8}
                            className="flexContainerColumn centred mt-5 mb-5"
                        >
                            <Button
                                type="submit"
                                disabled={this.props.submitting}
                                className="greenBtn header next uppercase"
                            >
                                {buttonName}
                            </Button>
                            {this.renderShareButton()}
                            {this.renderDeleteButton()}
                        </Col>
                    </form>
                </Col>
            </Container>
        );
    }
}

const contactForm = reduxForm({
    form: FORMS.SPECIALIST_FORM,
    validate: formUtils.validateContacts,
    destroyOnUnmount: true,
    enableReinitialize: true,
})(ContactsDetailScreen);

const mapStateToProps = (state, props) => {
    const id = props.match.params.id;
    return {
        initialValues: id ? state.contacts.contact : {},
        contacts: state.contacts.contacts,
        currentContact: id ? state.contacts.contact : {},
    };
};

export default connect(mapStateToProps, {
    setJournalTokenActiveStatusToTrue,
    setJournalTokenActiveStatusToFalse,
    handleStopSharingJournalContact,
    handleShareJournalClickContact,
    addContact,
    getContact,
    getContacts,
    updateContact,
    deleteContact,
    showDeleteModal,
    closeModal,
    showInfoModal,
    shareJournalThroughEmailContact,
    showConfirmModal,
    showRedConfirmModal
})(contactForm);