import React from 'react';

const CategoryItemIconWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        textWrap: 'pretty',
      }}
    >
      {children}
    </div>
  );
};

export default CategoryItemIconWrapper;
