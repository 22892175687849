import { useState } from 'react';
import * as i18n from 'i18next';

const SubscriptionCard = ({ plan, onClick, isSelected, withDescription }) => {
  const durationNumber = +plan.product.metadata.for;
  const durationText = durationNumber === 1 ? `${i18n.t('payments:month')}` : `${i18n.t('payments:months')}`;
  const price = (+plan.unitAmount / 100).toFixed(2);
  const pricePerMonth = (price / durationNumber).toFixed(2);
  const standartPrice = plan.product.metadata.standartPrice;
  const discount = Math.round((standartPrice * durationNumber - price) / (standartPrice * durationNumber) * 100);
  const [isClicked, setIsClicked] = useState(false);

  const selectPlan = () => {
    setIsClicked(!isClicked);
    if (onClick) onClick(plan.id);
  };

  const renderCardWithDiscount = () => {
    return (
      <div className='card-container'>
        <div className={`discount-container board brd-rds10 ${isSelected && 'selected-discount'}`}>
          <div className="discount"> {i18n.t('payments:save')} {discount}%</div>
          <div className={`subscription-card brd-rds10 ${isSelected && 'selected-card'}`} onClick={selectPlan}>
            <div className='package'>{i18n.t('payments:package')}</div>
            <div className="duration-number">{durationNumber}</div>
            <div className="duration-text">{durationText}</div>
            <div className="price">{price}{i18n.t('payments:currency')}</div>
            <div className="price-per-month">{pricePerMonth} {i18n.t('payments:currencyPerMonth')}</div>
          </div>
        </div>
        {withDescription &&
          <div className='plan-description'>
            {price}{i18n.t('payments:currency')}<br /> се изтеглят от<br /> картата ви<br />
            еднократно в<br /> началото на <br />периода.
          </div>
        }
      </div>
    );
  }

  const renderCardWithNoDiscount = () => {
    return (
      <div className='card-container'>
        <div className="discount hidden"> {i18n.t('payments:save')} {discount}%</div>
        <div className={`subscription-card-no-discount board brd-rds10 ${isSelected && 'selected-card'}`} onClick={selectPlan}>
          <div className='package hidden'>{i18n.t('payments:package')}</div>
          <div className="monthly-duration">
            {i18n.t('payments:monthly')}<br />
            {i18n.t('payments:subscription')}
          </div>
          <div className="duration-text hidden">{durationText}</div>
          <div className="price hidden">{price}{i18n.t('payments:currency')}</div>
          <div className="price-per-month">{pricePerMonth} {i18n.t('payments:currencyPerMonth')}</div>
        </div>
        <div className='plan-description'>
          {price}{i18n.t('payments:currency')}<br /> се изтеглят от <br />картата ви
          всеки<br /> месец в <br />продължение на<br /> до 14 месеца.<br />Можете да <br />прекратите по<br />
          всяко време.
        </div>
      </div>
    );
  }

  const renderActivePlan = () => {
    return (
      <div className='card-container'>
        <div className='subscription-card-no-discount board brd-rds10 selected-card active' onClick={selectPlan}>
          {discount === 0
            ?
            <>
              <div className='package hidden'>{i18n.t('payments:package')}</div>
              <div className="monthly-duration">
                {i18n.t('payments:monthly')}<br />
                {i18n.t('payments:subscription')}
              </div>
              <div className="duration-text hidden">{durationText}</div>
              <div className="price hidden">{price}{i18n.t('payments:currency')}</div>
            </>
            :
            <>
              <div className='package'>{i18n.t('payments:package')}</div>
              <div className="duration-number">{durationNumber}</div>
              <div className="duration-text">{durationText}</div>
              <div className="price">{price}{i18n.t('payments:currency')}</div>
            </>
          }
          <div className="price-per-month">{pricePerMonth} {i18n.t('payments:currencyPerMonth')}</div>
        </div>
      </div>
    );
  }

  return !withDescription
    ? renderActivePlan()
    : discount
      ? renderCardWithDiscount()
      : renderCardWithNoDiscount()
};

export default SubscriptionCard;