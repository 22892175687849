import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ITrackerRecord } from '../../interfaces/ITrackerRecord.interface';
import { IUser } from '../../interfaces/IUser.interface';
import BirthInfo from './components/BirthInfo';
import DatePickerCalendar from './components/DatePickerCalendar';
import FeedingFooter from './components/FeedingFooter';
import { extractRecordsForTable } from './helper/feedingAndDiapersUtils';
import { IFeedingAndDiapersTableRow } from './helper/IFeedingAndDiapersAnalytics.interface';
import FeedingTable from './components/FeedingTable';
import TableEmptyState from './components/TableEmptyState';
import { TRACKER_SUBTYPES } from '../../utils/constants';
import { DatesContext } from './components/DateProvider';

interface IFeedingAnalytics {
  trackerRecords: ITrackerRecord[];
  currentUser: IUser;
}
const FeedingAnalytics: React.FC<IFeedingAnalytics> = ({
  trackerRecords,
  currentUser,
}) => {
  const { startDate, endDate } = useContext(DatesContext);
  const [tableData, setTableData] = useState<IFeedingAndDiapersTableRow[]>();
  const { t } = useTranslation();

  const handleSearch = (startDate: Date, endDate: Date) => {
    const filteredRecords = trackerRecords
      .filter((record) =>
        [
          TRACKER_SUBTYPES.WEIGHT,
          TRACKER_SUBTYPES.POO_DIAPER,
          TRACKER_SUBTYPES.WET_DIAPER,
          TRACKER_SUBTYPES.BOTTLE_FORMULA,
          TRACKER_SUBTYPES.BOTTLE_BREAST_MILK,
          TRACKER_SUBTYPES.BREAST_FEEDING,
        ].includes(record.subType)
      )
      //@ts-ignore
      .sort((a, b) => new Date(a.date) - new Date(b.date));
    setTableData(extractRecordsForTable(startDate, endDate, filteredRecords));
  };

  useEffect(() => {
    if (endDate) {
      handleSearch(startDate, endDate);
    }
  }, [trackerRecords, endDate]);

  return (
    <div
      style={{
        marginBottom: '85px',
        width: '-webkit-fill-available',
      }}
    >
      <h5 className="primaryHeader">
        <p>{t('feedingAnalytics:title-feeding')} </p>
        <span>
          {t('feedingAnalytics:to')} {currentUser?.childName.toUpperCase()}
        </span>
      </h5>
      <BirthInfo />
      <DatePickerCalendar />
      {tableData && tableData.length > 0 ? (
        <div id="feeding-table">
          <FeedingTable data={tableData} />
        </div>
      ) : (
        <TableEmptyState trackerType={TRACKER_SUBTYPES.BOTTLE_FORMULA} />
      )}
      <FeedingFooter />
    </div>
  );
};

export default FeedingAnalytics;
