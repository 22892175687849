import moment from 'moment';
import { TIME_OF_THE_DAY } from '../../tracker/tracking/models';
import { ITrackerRecord } from '../../../interfaces/ITrackerRecord.interface';
import {
  IHoverSleep,
  ITableRowData,
  ITableTimeRemainder,
} from './ISleepAnalytics.interface';
import i18next from 'i18next';
import { calculateChildAge } from './utils';
import { DATE_FORMAT } from '../../../utils/constants';
import {
  BORDER_SIZE,
  CELL_NUMBER,
  HOVER_SLEEP_MARGIN,
  MIN_IN_HOUR,
  ONE_EXTRA_ROW,
  SLEEP_CALENDAR_BORDERS,
  SLEEP_CELL_SIZE,
  SLEEP_PX_PER_MIN,
  SLEEP_RECORD_COLORS,
} from './constants';

// =========================Sleep Table=========================
export const getSleepTableRowDataForDay = (
  recordsForDay: ITrackerRecord[],
  recordsForPastDay: ITrackerRecord[],
  previousDayRemainder: ITableTimeRemainder
): ITableRowData => {
  const { dayRecords, nightRecords, lullRecords } =
    separateSleepRecordsForDay(recordsForDay);

  let totalSleep = getTotalSleepTypeSeconds([...nightRecords, ...dayRecords]);
  const rowDate = new Date(recordsForDay[0].date);
  const rowMoment = moment(rowDate);

  let currentDayRemainder: ITableTimeRemainder = {
    action: '',
    remainder: 0,
  };

  const lastRecordForDay = recordsForDay[recordsForDay.length - 1];
  const lastRecordDate = new Date(lastRecordForDay.date);
  // Check if we have a record duration >00:00
  if (hasDatePassedMidnight(lastRecordDate, lastRecordForDay.quantity)) {
    const today = new Date();
    today.setHours(
      lastRecordDate.getHours(),
      lastRecordDate.getMinutes(),
      0,
      0
    );
    const incrementedDate = moment(today)
      .add(lastRecordForDay.quantity, 'seconds')
      .toDate();

    currentDayRemainder = {
      action: lastRecordForDay.additionalInfo,
      remainder:
        incrementedDate.getHours() * 60 * 60 +
        incrementedDate.getMinutes() * 60,
    };
    totalSleep -= currentDayRemainder.remainder;
  }

  let nighttimeSleep = getTotalSleepTypeSeconds(nightRecords);
  let daytimeSleep = getTotalSleepTypeSeconds(dayRecords);
  let lullTime = getTotalSleepTypeSeconds(lullRecords);
  let daytimeNaps = dayRecords.length;
  let totalAwakening = dayRecords.length + nightRecords.length + 1;

  switch (previousDayRemainder.action) {
    case TIME_OF_THE_DAY.NIGHT:
      nighttimeSleep += previousDayRemainder.remainder;
      break;
    case TIME_OF_THE_DAY.DAY:
      daytimeNaps += 1;
      daytimeSleep += previousDayRemainder.remainder;
      break;
    case TIME_OF_THE_DAY.LULL:
      lullTime += previousDayRemainder.remainder;
      break;
    default:
      break;
  }

  switch (currentDayRemainder.action) {
    case TIME_OF_THE_DAY.NIGHT:
      totalAwakening -= 1;
      nighttimeSleep -= currentDayRemainder.remainder;
      break;
    case TIME_OF_THE_DAY.DAY:
      totalAwakening -= 1;
      daytimeSleep -= currentDayRemainder.remainder;
      break;
    case TIME_OF_THE_DAY.LULL:
      lullTime -= currentDayRemainder.remainder;
      break;
    default:
      break;
  }

  const currentRowData = {
    dateAndAge: `${moment(rowDate).format(DATE_FORMAT)} ${calculateChildAge(
      rowMoment
    )}`,
    nighttimeSleep: nighttimeSleep,
    daytimeSleep: daytimeSleep,
    totalSleep: totalSleep + previousDayRemainder.remainder,
    totalAwakening: totalAwakening < 0 ? 0 : totalAwakening, //>>>> ???????????????? ALO
    daytimeNaps: daytimeNaps,
    nightWaking: getNightWakings(recordsForDay, recordsForPastDay),
    lull: lullTime,
  };

  return { currentRowData: currentRowData, timeRemainder: currentDayRemainder };
};

export const separateRecordsForPeriod = (
  startDate: Date,
  endDate: Date,
  records: ITrackerRecord[]
): ITrackerRecord[][] => {
  const start = moment(startDate).startOf('day');
  const end = moment(endDate).startOf('day');

  let filteredRecords = records.filter((record) => {
    const recordDate = moment(record.date).startOf('day');
    return recordDate >= start && recordDate <= end;
  });

  const separatedRecords: ITrackerRecord[][] = [];
  let recordsForDay = [];

  let currentDate = startDate;
  for (let i = 0; i < filteredRecords.length; i++) {
    let dateRecord = new Date(filteredRecords[i].date);
    if (
      dateRecord.getMonth() === currentDate.getMonth() &&
      dateRecord.getDay() === currentDate.getDay()
    ) {
      recordsForDay.push(filteredRecords[i]);
    } else {
      recordsForDay.length != 0 && separatedRecords.push(recordsForDay);

      currentDate = dateRecord;
      recordsForDay = [];
      recordsForDay.push(filteredRecords[i]);
    }
  }
  recordsForDay.length != 0 && separatedRecords.push(recordsForDay);

  return separatedRecords;
};

const separateSleepRecordsForDay = (sleepRecords: ITrackerRecord[]) => {
  const dayRecords: ITrackerRecord[] = [];
  const nightRecords: ITrackerRecord[] = [];
  const lullRecords: ITrackerRecord[] = [];

  sleepRecords.forEach((record: ITrackerRecord) => {
    switch (record.additionalInfo) {
      case TIME_OF_THE_DAY.DAY:
        dayRecords.push(record);
        break;
      case TIME_OF_THE_DAY.NIGHT:
        nightRecords.push(record);
        break;
      case TIME_OF_THE_DAY.LULL:
        lullRecords.push(record);
        break;
      default:
        break;
    }
  });

  return {
    dayRecords,
    nightRecords,
    lullRecords,
  };
};

const getTotalSleepTypeSeconds = (records: ITrackerRecord[]) => {
  return records.reduce((acc, record) => acc + record.quantity, 0);
};

export const secToHoursAndMinutes = (sec: number = 0) => {
  if (!sec) return '-';
  const duration = moment.duration(sec * 1000);
  const hours =
    duration.hours() === 0
      ? ''
      : i18next.t('sleepAnalytics:hours', { hours: duration.hours() });

  const minutes =
    duration.minutes() === 0
      ? ''
      : i18next.t('sleepAnalytics:minutes', { min: duration.minutes() });

  return `${hours} ${minutes}`;
};

const getNightWakings = (
  recordsForDay: ITrackerRecord[],
  recordsForPastDay: ITrackerRecord[] = []
) => {
  const dayAndNightRecords = recordsForDay.filter(
    (record) => record.additionalInfo != TIME_OF_THE_DAY.LULL
  );

  const lastDayRecords =
    recordsForPastDay.filter(
      (record) => record.additionalInfo != TIME_OF_THE_DAY.LULL
    ) ?? [];

  let nightWakingCount = 0;

  if (dayAndNightRecords.length > 0) {
    dayAndNightRecords.forEach((record, i) => {
      const nextRecord = dayAndNightRecords[i + 1];

      if (record.additionalInfo === TIME_OF_THE_DAY.NIGHT) {
        // check for two consecutive night records
        if (nextRecord && nextRecord.additionalInfo === TIME_OF_THE_DAY.NIGHT) {
          nightWakingCount++;
        }
        // check first and last records match
        if (
          i == 0 &&
          lastDayRecords.length > 0 &&
          lastDayRecords[lastDayRecords.length - 1].additionalInfo ===
            TIME_OF_THE_DAY.NIGHT
        ) {
          nightWakingCount++;
        }
      }
    });
  }

  return nightWakingCount;
};

export const formatNumber = (number) => {
  number = Number(number);

  if (isNaN(number) || !isFinite(number)) {
    return;
  }

  if (Number.isInteger(number)) {
    return number;
  } else {
    return Math.round(number * 100) / 100;
  }
};

//====================Sleep calendar====================
function hasDatePassedMidnight(myDate: Date, myValue: number) {
  const newDate = moment(myDate).add(myValue, 'seconds');
  return !newDate.isSame(myDate, 'day');
}

export const getRecordPositionStyle = (
  record: ITrackerRecord,
  rowPositionForDay: number,
  handleRecordHover: (divInfo: IHoverSleep | null) => void
) => {
  const date = new Date(record.date);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const stringDate = `${moment(date).format(DATE_FORMAT)}, ${moment(
    date
  ).format('HH:mm')}`;

  let totalWidth = (record.quantity / MIN_IN_HOUR) * SLEEP_PX_PER_MIN;
  let newDivWidth;

  // Check if the action is two days
  if (hasDatePassedMidnight(date, record.quantity)) {
    const today = new Date();
    today.setHours(hours, minutes, 0, 0);
    const newDate = moment(today).add(record.quantity, 'seconds').toDate();
    // width for the second div
    newDivWidth =
      (newDate.getHours() * 60 + newDate.getMinutes()) * SLEEP_PX_PER_MIN;
    // get the total width for the first div
    totalWidth -= newDivWidth;
  }

  // start of the first div
  let left =
    hours * SLEEP_CELL_SIZE + minutes * SLEEP_PX_PER_MIN + SLEEP_CELL_SIZE;
  // get the row of the div, based on the number of days
  let top = (rowPositionForDay + 1) * SLEEP_CELL_SIZE + BORDER_SIZE;

  const totalRecordElements = [
    <div
      key={`${record.id}-1`}
      id={record.id.toString()}
      className="sleep-record-div"
      style={{
        left: `${left}px`,
        top: `${top}px`,
        width: `${totalWidth}px`,
        background:
          SLEEP_RECORD_COLORS[record.additionalInfo as 'DAY' | 'NIGHT' | 'LULL']
            .color,
      }}
      onMouseEnter={() => {
        handleRecordHover({
          recordId: record.id,
          recordDate: stringDate,
          recordNote: record.notes,
          leftPosition: `${left + totalWidth + HOVER_SLEEP_MARGIN}px`,
          topPosition: `${top - HOVER_SLEEP_MARGIN}px`,
        });
      }}
      onMouseLeave={() => handleRecordHover(null)}
    ></div>,
  ];

  // if we have a second div
  if (newDivWidth) {
    // start of the calendar
    const leftCalendar = SLEEP_CELL_SIZE;
    // Add 1xSLEEP_CELL_SIZE(next row)
    const topNeWDiv =
      (rowPositionForDay + 1 + ONE_EXTRA_ROW) * SLEEP_CELL_SIZE + BORDER_SIZE;
    totalRecordElements.push(
      <div
        key={`${record.id}-2`}
        id={record.id.toString()}
        className="sleep-record-div"
        style={{
          left: `${leftCalendar}px`,
          top: `${topNeWDiv}px`,
          width: `${newDivWidth}px`,
          background:
            SLEEP_RECORD_COLORS[
              record.additionalInfo as 'DAY' | 'NIGHT' | 'LULL'
            ].color,
        }}
        onMouseEnter={() => {
          handleRecordHover({
            recordId: record.id,
            recordDate: stringDate,
            recordNote: record.notes,
            leftPosition: `${
              leftCalendar + newDivWidth + HOVER_SLEEP_MARGIN
            }px`,
            topPosition: `${topNeWDiv - HOVER_SLEEP_MARGIN}px`,
          });
        }}
        onMouseLeave={() => handleRecordHover(null)}
      ></div>
    );
  }

  // Return 1 or 2 divs
  return totalRecordElements;
};

export const generateTableHeader = () => {
  const divs = [];
  for (let i = 0; i < CELL_NUMBER; i++) {
    divs.push(
      <div key={i} className="sleep-header-cell-hours">
        {i != 0 && `${i}:00`}
      </div>
    );
  }

  return (
    <div className="sleep-header-row">
      <div className="sleep-row-hours">{divs}</div>
    </div>
  );
};

export const generateCalendarRow = (date: Date, rowNumber: number) => {
  const divs = [];
  for (let i = 0; i < CELL_NUMBER; i++) {
    divs.push(
      <div
        key={i}
        className={i == 0 ? 'sleep-column-cell-dates' : `sleep-cell hour-${i}`}
      >
        {i == 0 && `${moment(date).format('DD.MM')}`}
      </div>
    );
  }

  return (
    <div key={rowNumber} className="sleep-row">
      <div className="sleep-row-hours">{divs}</div>
    </div>
  );
};

export const generateTopLayerTable = (rowNumber: number) => {
  const divs = [];
  for (let i = 0; i < CELL_NUMBER; i++) {
    divs.push(
      <div
        key={`vertical-${i}`}
        className="top-layer-table"
        style={{
          borderLeft: SLEEP_CALENDAR_BORDERS,
          height: `${rowNumber * SLEEP_CELL_SIZE}px`,
          left: `${(i + 1) * SLEEP_CELL_SIZE - BORDER_SIZE}px`,
        }}
      ></div>
    );
  }

  return (
    <div
      key={rowNumber}
      className="sleep-row"
      style={{ position: 'absolute', top: SLEEP_CELL_SIZE }}
    >
      <div className="sleep-row-hours">{divs}</div>
    </div>
  );
};
