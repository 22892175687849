import jsPDF from 'jspdf';
import EXIF from 'exif-js';
import { FRONTEND_BASE_URL } from '../../../utils/constants';

export interface IFontData {
  family: string;
  url: string;
  style: string;
}

export const plainScriptFont: IFontData = {
  family: 'PlainScript-PDF',
  url: `${FRONTEND_BASE_URL}fonts/PlainScript.ttf`,
  style: 'normal',
};
export const nutinoFont: IFontData = {
  family: 'Nunito-Regular-PDF',
  url: `${FRONTEND_BASE_URL}fonts/Nunito-Regular.ttf`,
  style: 'normal',
};

function encodeFileToBase64(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (event) {
      if (event?.target?.result) {
        const base64String = event.target.result.toString().split(',')[1];
        resolve(base64String);
      }
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
}

export const loadFontToJSPDF = async (doc: jsPDF, fontData: IFontData) => {
  const fontBlob = await loadFont(fontData.url);
  const base64Font = await encodeFileToBase64(fontBlob);
  doc.addFileToVFS(`${fontData.family}.ttf`, base64Font);
  doc.addFont(`${fontData.family}.ttf`, `${fontData.family}`, fontData.style);
  doc.setFont(`${fontData.family}`);
  doc.getFont();
};

const loadFont = (fontUrl: string) => {
  return new Promise<File>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', fontUrl, true);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      } else {
        reject('Failed to load font file');
      }
    };
    xhr.onerror = () => {
      reject('Failed to load font file');
    };
    xhr.send();
  });
};

export const sectionBounding = (
  sectionElement: HTMLElement,
  pdfHeight: number
) => {
  const sectionRect = sectionElement.getBoundingClientRect();
  const sectionTop = sectionRect.top;
  const sectionBottom = sectionRect.bottom;

  const sectionIndexTop = Math.floor(sectionTop / pdfHeight);
  const sectionIndexBottom = Math.floor(sectionBottom / pdfHeight);

  return {
    sectionTop,
    sectionBottom,
    sectionIndexTop,
    sectionIndexBottom,
  };
};

export const fixTextOnImageSection = (
  sectionText: HTMLElement,
  pdfHeight: number,
  currentSectionByIndex: HTMLElement,
  state: { veryLongText: boolean; shouldCheckOnSecond: boolean },
  currentSection: {
    sectionTop: number;
    sectionBottom: number;
    sectionIndexTop: number;
    sectionIndexBottom: number;
  }
) => {
  const textBounding = sectionBounding(sectionText, pdfHeight);

  const pixelCloseToTheEnd =
    textBounding.sectionTop - pdfHeight * currentSection.sectionIndexTop;
  const isOverImage =
    textBounding.sectionIndexTop !== currentSection.sectionIndexTop;

  if (!state.shouldCheckOnSecond) {
    if (
      (pixelCloseToTheEnd >= 430 && pixelCloseToTheEnd <= 465) ||
      isOverImage
    ) {
      state.shouldCheckOnSecond = true;
      return;
    }
  }

  let differenceBetweenTopBot =
    textBounding.sectionBottom - textBounding.sectionTop;
  const sectionSpaceToRemove =
    pdfHeight * (textBounding.sectionIndexTop + 1) - textBounding.sectionTop;

  const textIsOnTheSamePage =
    textBounding.sectionIndexBottom === textBounding.sectionIndexTop;

  if (!textIsOnTheSamePage) {
    let text = sectionText.textContent as string;
    const emptyTextSection = currentSectionByIndex.querySelector(
      '#empty-text-section'
    ) as HTMLElement;

    if (emptyTextSection) {
      let secondHalf = '';

      while (
        differenceBetweenTopBot + 31.2 > sectionSpaceToRemove &&
        text.length > 0
      ) {
        const lastSpaceIndex = text.lastIndexOf(' ', text.length - 1);
        const chunk = text.slice(lastSpaceIndex + 1);
        text = text.slice(0, lastSpaceIndex);

        secondHalf = chunk + ' ' + secondHalf;
        sectionText.textContent = text;
        emptyTextSection.textContent = secondHalf;

        const updatedBounding = sectionBounding(sectionText, pdfHeight);
        differenceBetweenTopBot =
          updatedBounding.sectionBottom - updatedBounding.sectionTop;
      }

      emptyTextSection.style.display = 'block';

      const emptySection = currentSectionByIndex.querySelector(
        '#empty-section-main'
      ) as HTMLElement;

      const newSectionTextLocation = emptySection.querySelector(
        '#empty-section-text-location'
      ) as HTMLElement;

      newSectionTextLocation.appendChild(emptyTextSection);

      const newEmptySection = document.createElement('div');
      newEmptySection.appendChild(emptySection);

      const secondElement = newEmptySection.querySelector(
        '#empty-section-main'
      ) as HTMLElement;
      secondElement.style.display = 'block';
      secondElement.style.marginTop = 31.2 * 2 + 'px';
      state.veryLongText = true;
      currentSectionByIndex.appendChild(newEmptySection);
    }
  } else {
    state.shouldCheckOnSecond = true;
  }
};
export function adjustImageOrientation(
  image: any,
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D | null
) {
  return new Promise((resolve, reject) => {
    EXIF.getData(image, function () {
      //@ts-ignore
      const orientation = EXIF.getTag(this, 'Orientation');
      if (orientation) {
        //here we can decrease use image for faster render on the pdf, bigger number -> faster!
        //@ts-ignore
        //let imageWidth = EXIF.getTag(this, 'ImageWidth') / 10;
        let imageWidth = 250;
        //@ts-ignore
        //let imageHeight = EXIF.getTag(this, 'ImageHeight') / 10;
        let imageHeight = 250;

        let mimeType = image.src.split('.').pop().toLowerCase();

        canvas.width = imageWidth;
        canvas.height = imageHeight;

        if (ctx) {
          ctx.drawImage(image, 0, 0, imageWidth, imageHeight);
          const dataUrl = canvas.toDataURL(`image/${mimeType}`);
          if (dataUrl) {
            image.src = dataUrl;
          }
        }
      }

      resolve(true);
    });
  });
}
