import { startLoader, stopLoader } from './commonActions';
import { getAxiosWithToken } from '../utils/webApi';
import { LOAD_TRACKING_RECORDS } from './actionTypes';

export const addTrackingRecord =
  (subTypeId, date, quantity, additionalInfo) => async (dispatch) => {
    dispatch(startLoader());
    await getAxiosWithToken().post('trackers/records', {
      subTypeId,
      date,
      quantity,
      additionalInfo,
    });

    dispatch(getAllTrackingRecords());
  };

export const updateTrackingRecord =
  (id, date, quantity, additionalInfo, notes) => async (dispatch) => {
    dispatch(startLoader());

    await getAxiosWithToken().put(`trackers/records/${id}`, {
      id,
      date,
      quantity,
      additionalInfo,
      notes,
    });

    dispatch(getAllTrackingRecords());
  };

export const deleteTrackingRecord = (id) => async (dispatch) => {
  dispatch(startLoader());

  await getAxiosWithToken().delete(`trackers/records/${id}`);

  dispatch(getAllTrackingRecords());
};

export const getAllTrackingRecords = () => async (dispatch) => {
  dispatch(startLoader());

  const response = await getAxiosWithToken().get('trackers/records');

  dispatch(stopLoader());

  const returnedData = response.data;

  if (returnedData.success) {
    dispatch({ type: LOAD_TRACKING_RECORDS, payload: returnedData.data });
  }
};
