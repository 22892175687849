import { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { CANCELED, SELECTED_PLAN_ID, STARTING_POINT_URL } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { showInfoModal, startLoader, stopLoader, showConfirmModal, closeModal } from '../../actions';
import { getAxiosWithToken } from '../../utils/webApi';
import * as i18n from 'i18next';
import './SubscriptionPlans.css'
import SubscriptionCard from './SubscriptionCard';
import moment from 'moment';
import { toDate } from 'date-fns';

const SubscriptionPlans = () => {
  const [prices, setPrices] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [activeSubscription, setActiveSubscription] = useState(null);
  const [isUnlockedExternally, setIsUnlockedExternally] = useState(false);
  const [isSubscriptionInRenewalPeriod, setIsSubscriptionInRenewalPeriod] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [error, setError] = useState(null);

  const loggedInUserEmail = useSelector(state => state.authentication.userData.email);
  const userId = useSelector(state => state.authentication.userData.id);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startLoader());

    sessionStorage.removeItem(SELECTED_PLAN_ID);
    getStripeCustomer();
    getAllSubscriptionPlans();
    getUserData();
  }, []);

  useEffect(() => {
    if (customer) getUserActivePlan();
  }, [customer])

  useEffect(() => {
    setDefaultSelectedPlan();
  }, [prices, activeSubscription]);

  const getUserData = async () => {
    const { data } = await getAxiosWithToken().get(`user/${userId}`)
    setIsUnlockedExternally(data.data.isSubscribedToPaidStickers);
  }

  const setDefaultSelectedPlan = () => {
    if (activeSubscription) {
      setSelectedPlan(null);
      return;
    }

    const planWithDuration14 = prices.find(price => +price.product.metadata.for === 14);
    if (planWithDuration14) setSelectedPlan(planWithDuration14);
  }

  const getStripeCustomer = async () => {
    try {
      const { data } = await getAxiosWithToken().post('billing/create-customer', { email: loggedInUserEmail });
      setCustomer(data.customer);

    } catch (error) {
      console.error(error);
      setError(i18n.t('payments:errorLoadingPlans'));
    }
  };

  const getAllSubscriptionPlans = async () => {
    try {
      const { data } = await getAxiosWithToken().get('billing/subscription-plans');
      if (data.prices.length > 0) {
        setPrices(data.prices.sort((a, b) => parseInt(a.product.metadata.sortKey, 10) - parseInt(b.product.metadata.sortKey, 10)));

      } else {
        return <div>{i18n.t('payments:noPlansMsg')}</div>
      }
      dispatch(stopLoader());

    } catch (error) {
      console.error(error);
      setError(i18n.t('payments:errorLoadingPlans'));
    }
  };

  const getUserActivePlan = async () => {
    try {
      let expiryDate = null;
      const subscriptionResponse = await getAxiosWithToken().post('billing/user-subscriptions', { email: loggedInUserEmail });
      if (subscriptionResponse.data.subscriptions.length) {
        setActiveSubscription(subscriptionResponse.data.subscriptions[0]);
        expiryDate = moment(subscriptionResponse.data.subscriptions[0].cancelAt);
      } else {
        const packageResponse = await getAxiosWithToken().post('billing/user-subscription-from-database', userId);
        expiryDate = moment(packageResponse.data.cancelAt);
        if (packageResponse.data) setActiveSubscription(packageResponse.data);
      }

      const currentDate = moment();
      const hoursUntilExpiry = expiryDate.diff(currentDate, 'hours');
      const daysUntilExpiry = hoursUntilExpiry / 24;
      setIsSubscriptionInRenewalPeriod(daysUntilExpiry >= 0 && daysUntilExpiry <= 7);

    } catch (error) {
      console.error(error);
      setError(i18n.t('payments:errorLoadingPlans'));
    }
  }

  const createSubscription = async () => {
    dispatch(startLoader());

    if (isSubscriptionInRenewalPeriod && activeSubscription) {
      setSubscription({ priceId: selectedPlan.id, customer, isSubscriptionInRenewalPeriod });
    }
    else if (activeSubscription || isUnlockedExternally) dispatch(showInfoModal(i18n.t('payments:haveActivePlan')));
    else {
      setSubscription({ priceId: selectedPlan.id, customer, isSubscriptionInRenewalPeriod });
    }
    dispatch(stopLoader());

  };

  const cancelSubscription = () => {
    try {
      dispatch(
        showConfirmModal({
          message: i18n.t('payments:confirmCancelPlan'),
          onConfirm: async () => {
            dispatch(startLoader());


            const response = await getAxiosWithToken().post(`billing/cancel-subscription?user_id=${userId}`, { subscriptionId: activeSubscription.id ?? null });
            if (response.data.subscription && response.data.subscription.status === CANCELED) {
              setActiveSubscription(null);
              setIsUnlockedExternally(false);
              sessionStorage.removeItem(STARTING_POINT_URL);
              dispatch(showInfoModal(i18n.t('payments:cancelPlanMsg')));
            }

            dispatch(closeModal());
            dispatch(stopLoader());
          },
          onDeny: () => {
            dispatch(closeModal());
          },
        })
      );

    } catch (error) {
      console.error(error);
      dispatch(showInfoModal(i18n.t('payments:errorCancelSubscription')));
    }
  };
  if (subscription) history.push({ pathname: '/payments/checkout', state: { subscription } });

  if (error) return <div>{error}</div>

  return (
    <div className='plans-container'>

      <div className='info-text brd-rds10'>
        <h4 className='info-header capital'>ОТКЛЮЧЕТЕ <br />
          <p>неограничените възможности <br /> на дневника!</p>
        </h4>

        <ul className='info-list'>
          <li>Следете и споделяйте прогреса на вашето дете</li>
          <li>Проследявайте дневния режим с тракерите</li>
          <li>Споделяйте грижите за бебето с партньора</li>
          <li>Създайте пълен дневник за първата година</li>
        </ul>
      </div>

      <div className='access-header'>Получавате:</div>

      <ul className='info-list info-list-desktop'>
        <li>Достъп за всеки с профила, през всяко устройство едновременно</li>
        <li>Експорт в PDF и разпечатване на попълнения дневник</li>
        <li>Линк за преглеждане в реално време от близки и специалисти</li>
        <li>Удължен достъп до създадения дневник и обща тракер история след приключване на абонамента</li>
        <li>Достъп до данните и обобщенията във всички тракери</li>
        <li>Без рекламни банери</li>
      </ul>

      <div className='subscription-plans'>

        <div className="plans-list">
          {prices && prices.map(price => {
            return price.product.active &&
              <SubscriptionCard key={price.id} isSelected={selectedPlan?.id === price.id} plan={price} withDescription={true}
                onClick={() => setSelectedPlan(price)} />
          })}
        </div>

        <Button variant='contained' disabled={!selectedPlan} onClick={createSubscription}>
          {selectedPlan
            ? +selectedPlan.product.metadata.for === 1
              ? i18n.t('payments:payMonthly')
              : i18n.t('payments:payFor', { months: selectedPlan.product.metadata.for })
            : i18n.t('payments:selectPlan')
          }
        </Button>
      </div >
      <hr />

      <h4 className='my-plan'>{i18n.t('payments:myPlan')}</h4>
      {activeSubscription &&
        <div className='active-plan'>
          <div className='active-plan-content'>
            <SubscriptionCard plan={activeSubscription.id ? activeSubscription.items[0].price : activeSubscription.price} withDescription={false} />
            <div className='plan-expire-date'>
              {i18n.t('payments:valid')} {moment(activeSubscription.cancelAt).format('DD/MM/YYYY')}
            </div>
          </div>
          <button className='cancel-btn' onClick={cancelSubscription}>
            {i18n.t('payments:cancelPlan')}
          </button>
        </div>
      }
      {!activeSubscription && <div className='no-subscription'>{isUnlockedExternally ? i18n.t('payments:isUnlockedFromOtherSource') : i18n.t('payments:noActivePlan')}</div>}
      <div className='user-comments'>
        <h5 className='comment-header'>Какво казват родителите?</h5>
        <p className='comment'>
          "Искам да изкажа едно огромно БЛАГОДАРЯ <br />за това, че сте сътворили
          толкова полезно<br /> и удобно за ползване приложение! За мен то<br />
          е първи и основен помощник на новите<br /> родители. Необходимост е
          проследяването<br /> на основни показатели в дневния режим<br /> на бебчето, а тракерите
          безкрайно ни <br />улесняват. С таткото сме поравно ангажирани<br /> с нашата дъщеричка
          и възможността да <br />отваряме нашия дневник от две устройства<br /> е страхотна, тъй
          като всеки може да записва<br /> всичко, което се е случило, докато той гледа<br />
          бебчето, а след това другия може бързо да<br /> навакса с информацията само с
          един поглед.<br /> Изключително полезна също е частта с<br /> очаквани срокове за
          проявяване на нови<br /> умения и средни стойности за растеж и<br /> развитие -
          всичко е крато и ясно оформено.<br /> За мен това е безкрайно полезно, удобно,<br />
          лесно за ползване и определено незаменимо<br /> приложение, което препоръчвам
          на всички <br />свой приятели, които очакват бебчета.<br /><br />
          Отново благодаря и Ви желая успех!<br /> Когато нещо се прави с толкова много
          грижа,<br /> внимание и желание, неизменно ще доведе <br />до успех! :&#41;"
        </p>
        <div className='client'>Александрина Симеонова</div>
        <hr />
        <p className='comment'>
          "Много е приятно да използваш<br /> приложението,
          защото на още едно място<br /> виждаш прогреса на детето си и се <br />
          вдъхновяваш и радваш на развитието му."
        </p>
        <div className='client'>Плами</div>
        <hr />
        <p className='comment'>
          "Удобно е някой да помни вместо теб, било то<br /> и приложение."
        </p>
        <div className='client'>Т.Тодорова</div>
        <hr />
        <p className='comment'>
          "Приложението е прекрасно. Има достатъчно<br /> много информация за всеки месец.
          Може да <br /> се следи развитието на бебето, което много<br /> ме улеснява."
        </p>
        <div className='client'>Деница</div>
      </div>
    </div >
  );
}

export default withRouter(SubscriptionPlans);
