import * as React from 'react';
import { FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Slide,
  InputLabel,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal, showDeleteModal } from '../../actions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IRecordProps {
  currentNote: string;
  onSave?: (data: string) => void;
  onCancel?: () => void;
  onDelete?: () => void;
}

const TrackerRecordNote: FC<IRecordProps> = ({
  currentNote,
  onSave,
  onCancel,
  onDelete,
}) => {
  const [noteValue, setNoteValue] = useState(currentNote || '');
  const [noteValidation, setNoteValidation] = useState<string | null>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const saveNote = () => {
    if (noteValue.trim() === '') {
      setNoteValidation(t('trackerRecordNote:validation.empty-note-message'));
    } else {
      setNoteValidation(null);
      onSave && onSave(noteValue);
      closeNote();
    }
  };
  const closeNote = () => {
    onCancel && onCancel();
  };

  const deleteNote = () => {
    const message = t('trackerRecordNote:confirmDeleteNote');
    dispatch(
      showDeleteModal({
        message,
        onDelete: () => {
          onDelete && onDelete();
          closeNote();
          dispatch(closeModal());
        },
      })
    );
    closeNote();
  };

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      maxWidth={'xs'}
      fullWidth={true}
      onClose={closeNote}
    >
      <DialogTitle id="note-title">{t('trackerRecordNote:note')}</DialogTitle>
      <DialogContent id="note-field-box">
        <TextField
          id="filled-multiline-static"
          fullWidth={true}
          size="small"
          inputProps={{ maxLength: 30 }}
          value={noteValue}
          onChange={(e) => setNoteValue(e.target.value)}
        />
        {noteValidation && <InputLabel error>{noteValidation}</InputLabel>}
      </DialogContent>
      <DialogActions id="note-button-box">
        {!currentNote ? (
          <Button
            variant="outlined"
            className="delete-button"
            onClick={closeNote}
          >
            {t('trackingEditDialog:buttons.close')}
          </Button>
        ) : (
          <Button className="delete-button" onClick={deleteNote}>
            {t('trackingEditDialog:buttons.delete')}
          </Button>
        )}
        <Button variant="contained" className="save-button" onClick={saveNote}>
          {t('trackingEditDialog:buttons.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TrackerRecordNote;
