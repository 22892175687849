import { useTranslation } from 'react-i18next';

const SleepFooter = () => {
  const { t } = useTranslation();
  return (
    <div id="growth-footer">
      <div id="view-more">
        <p>{t('sleepAnalytics:footer.is-it-sleeping')}</p>
        <a
          target="_blank"
          href="https://www.namama.bg/category/griji-i-stimuli/rejim/"
        >
          {t('sleepAnalytics:footer.view-more')}
        </a>
      </div>
    </div>
  );
};

export default SleepFooter;
