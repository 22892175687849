import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { Tabs, Tab, Box } from '@mui/material';
import ReactLoading from 'react-loading';
import i18n from 'i18next';
import {
  deleteUserProfiles,
  getPaidFunctionalityCredentials,
  lockPaidFunctionality,
  showDeleteModal,
  unlockPaidFunctionality,
} from '../actions';
import { FORMS, COLOR_SCHEME } from '../utils/constants';
import { formUtils } from '../utils/formUtils';
import { Fields } from './common/Fields';
import LocalNavigationComponent from './LocalNavigationComponent';
import DownloadPDFButton from './journal/pdf/DownloadPDFButton';
import { formValueSelector } from 'redux-form';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const selector = formValueSelector('adminForm');

const PaidFunctionalityScreen = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const paidFunctionalityCredentials = useSelector(
    (state) => state.paidFunctionalityCredentials
  );
  const dispatch = useDispatch();

  const handleTabChange = (event, newValue) => setActiveTab(newValue);

  const emailFromForm = useSelector((state) => selector(state, 'userEmail'));
  const [email, setEmail] = useState('');

  useEffect(() => {
    setEmail(emailFromForm);
  }, [emailFromForm]);

  const onLogin = (formValues) =>
    dispatch(getPaidFunctionalityCredentials(formValues));

  const handleUserEmails = (formValues, action) => {
    let userEmails = formValues.userEmails.split(',');
    userEmails = userEmails.map((email) => email.trim());
    dispatch(action({ paidFunctionalityCredentials, userEmails }));
  };

  const onLock = (formValues) =>
    handleUserEmails(formValues, lockPaidFunctionality);

  const onUnlock = (formValues) =>
    handleUserEmails(formValues, unlockPaidFunctionality);

  const onDelete = (formValues) => {
    let userEmails = formValues.userEmails.split(',');
    userEmails = userEmails.map((email) => email.trim());
    dispatch(
      showDeleteModal({
        message: i18n.t('paidFunctionalityScreen:confirmDelete'),
        onDelete: () => {
          dispatch(
            deleteUserProfiles({ paidFunctionalityCredentials, userEmails })
          );
        },
      })
    );
  };

  const renderFields = () => (
    <Fragment>
      {Fields.paidFunctionality.userEmails({
        className: 'input-container user-emails',
      })}
    </Fragment>
  );

  const renderButtons = () => (
    <Fragment>
      <Button
        className="greenBtn header next uppercase"
        onClick={props.handleSubmit(onUnlock)}
      >
        {i18n.t('paidFunctionalityScreen:buttons.unlock')}
      </Button>
      <Button
        className="header next uppercase redBtn"
        onClick={props.handleSubmit(onLock)}
      >
        {i18n.t('paidFunctionalityScreen:buttons.lock')}
      </Button>
      <Button
        className="header next uppercase redBtn"
        onClick={props.handleSubmit(onDelete)}
      >
        {i18n.t('paidFunctionalityScreen:buttons.deleteProfiles')}
      </Button>
    </Fragment>
  );

  const renderProfileManagementTab = () => (
    <form className="flexContainerColumn flexContainerCentered fix-padding">
      {renderFields()}
      <Col
        xs={10}
        sm={10}
        md={10}
        lg={8}
        className="flexContainerColumn centred"
        style={{ margin: '24px 0px 12px 0px' }}
      >
        {renderButtons()}
      </Col>
    </form>
  );

  const renderPdfGenerationTab = () => (
    <div>
      <div
        className="flexContainerColumn flexContainerCentered"
        style={{ margin: '24px 0px' }}
      >
        {Fields.paidFunctionality.userEmail()}
      </div>

      <Col
        xs={10}
        sm={10}
        md={10}
        lg={8}
        className="flexContainerColumn centred"
      >
        <DownloadPDFButton
          isAdminPage={true}
          setLoading={setLoading}
          email={email}
        />
      </Col>
    </div>
  );

  const renderAuthenticationForm = () => (
    <form className="flexContainerColumn flexContainerCentered fix-padding">
      <Fragment>
        {Fields.paidFunctionality.username()}
        {Fields.commonFields.password()}
      </Fragment>

      <Col
        xs={10}
        sm={10}
        md={10}
        lg={8}
        className="flexContainerColumn centred"
        style={{ margin: '24px 0px 12px 0px' }}
      >
        <Fragment>
          <Button
            className="greenBtn header next uppercase"
            onClick={props.handleSubmit(onLogin)}
          >
            {i18n.t('buttons:next')}
          </Button>
        </Fragment>
      </Col>
    </form>
  );

  const { username, password } = paidFunctionalityCredentials;

  return (
    <Container className="noPadding">
      <Col xs={12} sm={12} md={4} lg={4} className="centred noPadding">
        <div className="flexContainerColumn flexContainerCentered fix-padding">
          {loading ? (
            <div className="spinner-modal">
              <ReactLoading
                type="spinningBubbles"
                color={COLOR_SCHEME.GREEN}
                height="auto"
                width={150}
              />
            </div>
          ) : (
            <>
              <LocalNavigationComponent
                header={i18n.t('paidFunctionalityScreen:title')}
              />
              {username && password ? (
                <Box sx={{ width: '100%' }}>
                  <Tabs
                    orientation="vertical"
                    value={activeTab}
                    onChange={handleTabChange}
                    aria-label="paid functionality tabs"
                    TabIndicatorProps={{ style: { display: 'none' } }}
                  >
                    <Tab
                      label={i18n.t(
                        'paidFunctionalityScreen:tabs.profileManagement'
                      )}
                      value={0}
                    />
                    <Tab
                      label={i18n.t(
                        'paidFunctionalityScreen:tabs.pdfGeneration'
                      )}
                      value={1}
                    />
                  </Tabs>
                  <TabPanel value={activeTab} index={0}>
                    {renderProfileManagementTab()}
                  </TabPanel>
                  <TabPanel value={activeTab} index={1}>
                    {renderPdfGenerationTab()}
                  </TabPanel>
                </Box>
              ) : (
                renderAuthenticationForm()
              )}
            </>
          )}
        </div>
      </Col>
    </Container>
  );
};

const adminForm = reduxForm({
  form: FORMS.ADMIN_FORM,
  validate: formUtils.validatePaidFunctionality,
})(PaidFunctionalityScreen);

export default adminForm;
