import { useMemo } from 'react';
import {
  type MRT_ColumnDef,
  MRT_Table,
  useMantineReactTable,
  MRT_TableInstance,
} from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import TrackerIcon from './TrackerIcon';
import { IFeedingAndDiapersTableRow } from '../helper/IFeedingAndDiapersAnalytics.interface';

interface IFeedingTable {
  data: IFeedingAndDiapersTableRow[];
}

const FeedingTable: React.FC<IFeedingTable> = ({ data }) => {
  const { t } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<IFeedingAndDiapersTableRow>[]>(
    () => [
      {
        accessorKey: 'dateAndAge',
        header: t('sleepAnalytics:tableHeaders.date'),
        Header: ({}) => (
          <div className="header-cell-name" style={{ textAlign: 'center' }}>
            {t('sleepAnalytics:tableHeaders.dateAnd')}
            <br />
            {t('sleepAnalytics:tableHeaders.Age')}
          </div>
        ),
        Cell: (item) => {
          const [date, age] = item.renderedCellValue?.toString().split('-');
          return (
            <div className="header-cell-name">
              {date}
              <br />
              {age}
            </div>
          );
        },
        size: 5,
        maxSize: 2,
        minSize: 1,
      },
      {
        accessorKey: 'weight',
        header: 'weightIcon',
        Header: ({}) => <TrackerIcon trackerIcon={'tracker-weight'} />,
        Cell: (item) => {
          return (
            <div>
              {`${item.renderedCellValue}${t('trackingScreen:symbols.kg')}`}
            </div>
          );
        },
        size: 5,
        maxSize: 10,
      },
      {
        accessorKey: 'wetDiapers',
        header: 'wetDiapersIcon',
        Header: ({}) => <TrackerIcon trackerIcon={'tracker-wet-pampers'} />,
        size: 5,
        maxSize: 10,
      },
      {
        accessorKey: 'pooDiapers',
        header: 'pooDiapers',
        Header: ({}) => <TrackerIcon trackerIcon={'tracker-poo-pampers'} />,
        size: 5,
      },
      {
        accessorKey: 'breastFeedings',
        header: 'breastFeedings',
        Header: ({}) => <TrackerIcon trackerIcon={'tracker-breastfeeding'} />,
        size: 10,
      },
      {
        accessorKey: 'totalBreastMilk',
        header: 'totalBreastMilk',
        Header: ({}) => (
          <TrackerIcon trackerIcon={'tracker-breast-milk-bottle'} />
        ),
        Cell: (item) => {
          const [number, quantity] = item.renderedCellValue
            ?.toString()
            .split('/');
          return (
            <div className="header-cell-name">
              {number}/
              <br />
              {`${quantity}${t('trackingScreen:symbols.ml')}`}
            </div>
          );
        },
        size: 1,
      },
      {
        accessorKey: 'totalFormula',
        header: 'totalFormula',
        Header: ({}) => <TrackerIcon trackerIcon={'tracker-formula-bottle'} />,
        Cell: (item) => {
          const [number, quantity] = item.renderedCellValue
            ?.toString()
            .split('/');
          return (
            <div className="header-cell-name">
              {number}/
              <br />
              {`${quantity}${t('trackingScreen:symbols.ml')}`}
            </div>
          );
        },
        size: 10,
      },
    ],
    []
  );

  const table: MRT_TableInstance<any> = useMantineReactTable({
    columns,
    data,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: false,
    renderEmptyRowsFallback: () => <div>Empty state</div>,
    mantineTableHeadCellProps: {
      align: 'center',
      style: {
        backgroundColor: 'rgba(247, 147, 30, 0.08)',
        color: '#000000',
        padding: '8px 8px',
        lineHeight: '9px',
        fontSize: '12px',
        fontWeight: 300,
        fontFamily: 'Nunito',
        alignContent: 'center',
        boxShadow: 'none',
        borderWidth: '6px 8px 0px 8px',
        borderStyle: 'solid',
        borderColor: 'rgba(247, 147, 30, 0.08)',
      },
    },
    mantineTableBodyCellProps: {
      style: {
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '11.7px',
        fontFamily: 'Nunito',
        color: '#323B4B',
        textAlign: 'center',
        borderWidth: '6px 8px 6px 8px',
        borderStyle: 'solid',
        borderColor: 'rgba(247, 147, 30, 0.08)',
      },
    },
  });

  return <MRT_Table table={table} />;
};

export default FeedingTable;
