import React from 'react';
import i18n from '../i18next';
import { MAX_TEXT_FIELD_LENGTH, MIN_TEXT_FIELD_LENGTH } from './constants';

const emailReg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const separatedEmailsReg =
    /^(\s? *?[^\s,]+[^\s,]+\s? *?,)*(\s? *?[^\s,]+[^\s,]+\s? *?)$/;

export const commonValidation = (formValues, errors, fieldName) => {
    let error = '';

    function required() {
        if (!formValues[fieldName]) {
            error = i18n.t('auth:errors.requiredField');
        }

        return this;
    }

    function minLength(minLength) {
        if (formValues[fieldName] && formValues[fieldName].length < minLength) {
            error = i18n.t('auth:errors.fieldMinLength', { number: minLength });
        }

        return this;
    }

    function maxLength(maxLength) {
        if (formValues[fieldName] && formValues[fieldName].length > maxLength) {
            error = i18n.t('auth:errors.fieldMaxLength', { number: maxLength });
        }

        return this;
    }

    function email() {
        const emailAddress = formValues[fieldName];
        // Only returning error if there is entered text for the email address.
        // If there is no value - the email isn't considered 'invalid'
        if (
            emailAddress &&
            !emailReg.test(String(emailAddress).toLowerCase())
        ) {
            error = i18n.t('auth:errors.emailNotValid');
        }

        return this;
    }

    function validate() {
        if (error) {
            return (errors[fieldName] = error);
        } else {
            return errors[fieldName];
        }
    }

    return {
        required,
        minLength,
        maxLength,
        email,
        validate,
    };
};

export const formUtils = {
    validateSignUp: (formValues, props) => {
        let errors = {};
        if (!emailReg.test(String(formValues.email).toLowerCase())) {
            errors.email = i18n.t('auth:errors.emailNotValid');
        }

        commonValidation(formValues, errors, 'email')
            .required()
            .maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
            .validate();
        // commonValidation(formValues, errors, 'isChildBorn4WeeksBeforeDueDate').required().validate();
        commonValidation(formValues, errors, 'password')
            .required()
            .minLength(MIN_TEXT_FIELD_LENGTH.PASSWORD)
            .maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
            .validate();
        if (formValues.password !== formValues.repeatPassword) {
            errors.repeatPassword = i18n.t('auth:errors.passwordsMismatch');
        }
        commonValidation(formValues, errors, 'repeatPassword')
            .required()
            .validate();

        commonValidation(formValues, errors, 'motherOrFatherName')
            .required()
            .maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
            .validate();
        commonValidation(formValues, errors, 'town')
            .required()
            .maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
            .validate();

        commonValidation(formValues, errors, 'childName')
            .maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
            .validate();
        commonValidation(formValues, errors, 'childDateOfBirth')
            .required()
            .validate();
        commonValidation(formValues, errors, 'areTermsAccepted')
            .required()
            .validate();
        commonValidation(formValues, errors, 'areGDPRAccepted')
            .required()
            .validate();

        return errors;
    },

    validateRequestPasswordReset: (formValues, props) => {
        let errors = {};
        commonValidation(formValues, errors, 'email')
            .required()
            .maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
            .email()
            .validate();

        return errors;
    },

    validateResetPassword: (formValues, props) => {
        let errors = {};
        commonValidation(formValues, errors, 'password')
            .required()
            .minLength(MIN_TEXT_FIELD_LENGTH.PASSWORD)
            .maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
            .validate();
        if (formValues.password !== formValues.repeatPassword) {
            errors.repeatPassword = i18n.t('auth:errors.passwordsMismatch');
        }
        commonValidation(formValues, errors, 'repeatPassword')
            .required()
            .validate();

        return errors;
    },

    validateChangePassword: (formValues, props) => {
        let errors = {};

        commonValidation(formValues, errors, 'oldPassword')
            .required()
            .minLength(MIN_TEXT_FIELD_LENGTH.PASSWORD)
            .maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
            .validate();

        commonValidation(formValues, errors, 'password')
            .required()
            .minLength(MIN_TEXT_FIELD_LENGTH.PASSWORD)
            .maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
            .validate();
        if (formValues.password !== formValues.repeatPassword) {
            errors.repeatPassword = i18n.t('auth:errors.passwordsMismatch');
        }

        commonValidation(formValues, errors, 'repeatPassword')
            .required()
            .validate();

        return errors;
    },

    validateLogin: (formValues, props) => {
        let errors = {};
        commonValidation(formValues, errors, 'email')
            .required()
            .maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
            .email()
            .validate();
        commonValidation(formValues, errors, 'password')
            .required()
            .minLength(MIN_TEXT_FIELD_LENGTH.PASSWORD)
            .maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
            .validate();

        return errors;
    },

    validateProfile: (formValues, props) => {
        let errors = {};

        commonValidation(formValues, errors, 'motherOrFatherName')
            .required()
            .maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
            .validate();
        commonValidation(formValues, errors, 'town')
            .required()
            .maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
            .validate();

        commonValidation(formValues, errors, 'childName')
            .required()
            .maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
            .validate();

        return errors;
    },

    validateAddSticker: (formValues, props) => {
        let errors = {};
        commonValidation(formValues, errors, 'entered').required().validate();

        return errors;
    },

    validateSpecialists: (formValues, props) => {
        let errors = {};
        if (
            formValues.email &&
            !emailReg.test(String(formValues.email).toLowerCase())
        ) {
            errors.email = i18n.t('auth:errors.emailNotValid');
        }

        commonValidation(formValues, errors, 'email')
            .maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
            .validate();
        commonValidation(formValues, errors, 'notes')
            .maxLength(MAX_TEXT_FIELD_LENGTH.VERY_LONG)
            .validate();
        commonValidation(formValues, errors, 'phoneNumber')
            .maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
            .validate();
        commonValidation(formValues, errors, 'name')
            .required()
            .maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
            .validate();
        commonValidation(formValues, errors, 'typeOfServices')
            .maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
            .validate();

        return errors;
    },
    validateContacts: (formValues, props) => {
        let errors = {};
        
        if (formValues) {
        if (
            formValues.email &&
            !emailReg.test(String(formValues.email).toLowerCase())
        ) {
            errors.email = i18n.t('auth:errors.emailNotValid');
        }
    }

        commonValidation(formValues, errors, 'email')
            .maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
            .validate();
        commonValidation(formValues, errors, 'notes')
            .maxLength(MAX_TEXT_FIELD_LENGTH.VERY_LONG)
            .validate();
        commonValidation(formValues, errors, 'phoneNumber')
            .maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
            .validate();
        commonValidation(formValues, errors, 'name')
            .required()
            .maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
            .validate();
        commonValidation(formValues, errors, 'typeOfServices')
            .maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
            .validate();

        return errors;
    },
    validatePaidFunctionality: (formValues, props) => {
        let errors = {};
        if (
            !formValues.userEmails ||
            !separatedEmailsReg.test(
                String(formValues.userEmails).toLowerCase()
            )
        ) {
            errors.userEmails = i18n.t(
                'paidFunctionalityScreen:errors.emailsNotValid'
            );
        }
        commonValidation(formValues, errors, 'username').required().validate();
        commonValidation(formValues, errors, 'password').required().validate();

        return errors;
    },

    renderErrorMessage: (meta) => {
        if (meta.error && meta.touched) {
            return (
                <span className="text-danger position-absolute">
                    {meta.error}
                </span>
            );
        }
    },

    getFieldStyle: (meta) => {
        let className = 'form-control form-field white-input-field';
        if (meta.error && meta.touched) {
            className += ' alert-danger';
        }

        return className;
    },
};
