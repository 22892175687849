import React, { useEffect, useMemo, useState } from 'react';
import WHOdata from './helper/WHOdata.json';
import { useTranslation } from 'react-i18next';
import ReactECharts from 'echarts-for-react';
import {
  IGraphData,
  IGraphDataWeightForLength,
  IPercentileDaysData,
} from './helper/IGrowthCurves.interface';
import {
  CHART_TYPE,
  RELATIVE_PAGE_PATHS,
  TRACKER_SUBTYPES,
} from '../../utils/constants';
import {
  getOptionsForGrowth,
  getOptionsWeightForLength,
  getPairsForWeightLength,
  getPercentilesForCategory,
  getPercentilesWeightForLength,
  getValuesForTracker,
} from './helper/utils';
import BirthInfo from './components/BirthInfo';
import GrowthFooter from './components/GrowthFooter';
import './helper/growth.scss';
import TrackerSeparator from './components/TrackerSeparator';
import { ITrackerRecord } from '../../interfaces/ITrackerRecord.interface';
import { IUser } from '../../interfaces/IUser.interface';
import { useDispatch } from 'react-redux';
import TrackerSeparatorNoHover from './components/TrackerSeparatorNoHover';


interface IGrowthCurves {
  trackerRecords: ITrackerRecord[];
  currentUser: IUser | null;
}

const GrowthCurves: React.FC<IGrowthCurves> = ({
  trackerRecords,
  currentUser,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [weightData, setWeightData] = useState<IGraphData>();
  const [heightData, setHeightData] = useState<IGraphData>();
  const [headCircumferenceData, setHeadCircumference] = useState<IGraphData>();
  const [weightForLength, setWeightForLength] =
    useState<IGraphDataWeightForLength>();

  useMemo(() => {
    if (trackerRecords) {
      const weightData = getValuesForTracker(
        trackerRecords,
        TRACKER_SUBTYPES.WEIGHT
      );

      const heightData = getValuesForTracker(
        trackerRecords,
        TRACKER_SUBTYPES.HEIGHT
      );

      const headCircumferenceData = getValuesForTracker(
        trackerRecords,
        TRACKER_SUBTYPES.HEAD_CIRCUMFERENCE
      );

      const weightForLength = getPairsForWeightLength(weightData, heightData);

      if (!currentUser) return;

      const genderForData = currentUser.isChildGirl ? 'girls' : 'boys';

      const percentilesWeightData = getPercentilesForCategory(
        WHOdata.weightForAge[genderForData] as IPercentileDaysData,
        weightData.length
      );
      setWeightData({
        childData: weightData,
        WHOcategoryData: percentilesWeightData,
      });

      const percentilesHeightData = getPercentilesForCategory(
        WHOdata.heightForAge[genderForData] as IPercentileDaysData,
        heightData.length
      );
      setHeightData({
        childData: heightData,
        WHOcategoryData: percentilesHeightData,
      });

      const percentilesHeadCircumferenceData = getPercentilesForCategory(
        WHOdata.headCircumference[genderForData] as IPercentileDaysData,
        headCircumferenceData.length
      );
      setHeadCircumference({
        childData: headCircumferenceData,
        WHOcategoryData: percentilesHeadCircumferenceData,
      });

      const percentilesWeightForLength = getPercentilesWeightForLength(
        WHOdata.weightForLength[genderForData] as IPercentileDaysData
      );
      setWeightForLength({
        childData: weightForLength,
        WHOcategoryData: percentilesWeightForLength,
      });
    }
  }, [trackerRecords, currentUser]);

  return (
    <div
      style={{
        marginBottom: '85px',
        width: '-webkit-fill-available',
      }}
    >
      <h5 className="primaryHeader">
        <p>{t('growthCurves:title-growth')} </p>
        <span>
          {t('growthCurves:to')} {currentUser?.childName.toUpperCase()}
        </span>
      </h5>
      <BirthInfo />
      <div id="charts">
        <div className="chart-section" id="weight">
          <TrackerSeparator
            trackerLink={RELATIVE_PAGE_PATHS.WEIGHT_TRACKER}
            trackerIcon={'separator-icon-weight.svg'}
          />
          <div className="chart-titles">
            <h6>{t('growthCurves:weight-title')}</h6>
          </div>
          <div className="chart">
            {weightData && (
              <ReactECharts
                className="growth-charts"
                option={getOptionsForGrowth(
                  weightData,
                  t('growthCurves:units.kg'),
                  t('growthCurves:weight(unit)'),
                  currentUser?.childName,
                  CHART_TYPE.WEIGHT
                )}
              />
            )}
          </div>
        </div>
        <div className="chart-section" id="height">
          <TrackerSeparator
            trackerLink={RELATIVE_PAGE_PATHS.HEIGHT_TRACKER}
            trackerIcon={'separator-icon-height.svg'}
          />
          <div className="chart-titles">
            <h6>{t('growthCurves:height-title')}</h6>
          </div>
          <div className="chart">
            {heightData && (
              <ReactECharts
                className="growth-charts"
                option={getOptionsForGrowth(
                  heightData,
                  t('growthCurves:units.cm'),
                  t('growthCurves:height(unit)'),
                  currentUser?.childName,
                  CHART_TYPE.HEIGHT
                )}
              />
            )}
          </div>
        </div>
        <div className="chart-section" id="circumference">
          <TrackerSeparator
            trackerLink={RELATIVE_PAGE_PATHS.HEAD_CIRCUMFERENCE_TRACKER}
            trackerIcon={'separator-icon-circumference.svg'}
          />
          <div className="chart-titles">
            <h6>{t('growthCurves:headCircumference-title')}</h6>
          </div>
          <div className="chart">
            {headCircumferenceData && (
              <ReactECharts
                className="growth-charts"
                option={getOptionsForGrowth(
                  headCircumferenceData,
                  t('growthCurves:units.cm'),
                  t('growthCurves:headCircumference(unit)'),
                  currentUser?.childName,
                  CHART_TYPE.HEAD_CIRCUMFERENCE
                )}
              />
            )}
          </div>
        </div>
        <div className="chart-section">
          <TrackerSeparatorNoHover trackerIcon={'separator-icon-growth.svg'} />
          <div className="chart-titles">
            <h6>{t('growthCurves:weight-for-length')}</h6>
          </div>
          <div className="chart">
            {weightForLength && (
              <ReactECharts
                className="growth-charts"
                option={getOptionsWeightForLength(
                  weightForLength,
                  t('growthCurves:units.kg'),
                  t('growthCurves:weight(unit)'),
                  currentUser?.childName,
                  CHART_TYPE.WEIGHT_FOR_LENGTH
                )}
              />
            )}
          </div>
        </div>
      </div>
      <GrowthFooter />
    </div>
  );
};

export default GrowthCurves;
