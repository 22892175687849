import { IconButton, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { RELATIVE_PAGE_PATHS, TRACKERS_SUB_TYPE } from '../../utils/constants';
import RouterLink from '../common/RouterLink';
import { MoreVert } from '@mui/icons-material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { closeModal, showDeleteModal } from '../../actions';
import { useDispatch } from 'react-redux';

const InnerLink = ({ record, children, setModal }) => {
  // if (disableLink) {
  //   return <div className="d-flex py-1">{children}</div>;
  // }
  const getRelativePath = () => {
    switch (record.name) {
      case TRACKERS_SUB_TYPE.BREAST_FEEDING:
        return `${RELATIVE_PAGE_PATHS.BREAST_FEEDING}/${record.id}`;
      case TRACKERS_SUB_TYPE.SLEEP:
        return `${RELATIVE_PAGE_PATHS.SLEEP}/${record.id}`;
      case TRACKERS_SUB_TYPE.CRY:
        return `${RELATIVE_PAGE_PATHS.CRY}/${record.id}`;
      default:
        return `${RELATIVE_PAGE_PATHS.TRACKING}/${record.subTypeId}/${record.id}`;
    }
  };

  const to = getRelativePath();
  return (
    <RouterLink setModal={setModal} to={to}>
      <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
    </RouterLink>
  );
};

const TrackerHistoryItem = ({
  record,
  children,
  onEdit,
  onAdd,
  onDelete,
  disableEdit,
  hideEdit,
  disableLink,
  setModal,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleEdit = useCallback(() => {
    if (onEdit) {
      onEdit(record.id, record.subTypeId);
    }
    handleClose();
  }, [onEdit, handleClose, record]);

  const handleDelete = useCallback(() => {
    if (onDelete) {
      const message = t('trackingScreen:confirmDeleteRecord');
      dispatch(
        showDeleteModal({
          message,
          onDelete: () => {
            onDelete(record.id);
            dispatch(closeModal());
          },
        })
      );
    }
    handleClose();
  }, [onDelete, handleClose, record]);

  const addNote = useCallback(() => {
    if (onAdd) {
      onAdd(record.id, record.subTypeId);
    }
    handleClose();
  }, [onAdd, handleClose, record]);

  return (
    <div
      className="trackerHistory-container"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        widht: '100%',
        alignItems: 'center',
        marginBottom: '5px',
        marginLeft: '15px',
      }}
    >
      <InnerLink record={record} setModal={setModal}>
        {children}
      </InnerLink>
      {!hideEdit && (
        <div>
          <IconButton
            aria-label="delete"
            onClick={handleClick}
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <MoreVert />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              dense: true,
            }}
          >
            <MenuItem disabled={disableEdit} onClick={handleEdit}>
              {t('trackingHistoryItem:buttons.edit')}
            </MenuItem>
            <MenuItem disabled={disableEdit} onClick={handleDelete}>
              {t('trackingHistoryItem:buttons.delete')}
            </MenuItem>
            <MenuItem disabled={disableEdit} onClick={addNote}>
              {record.notes
                ? t('trackingHistoryItem:buttons.editNote')
                : t('trackingHistoryItem:buttons.addNote')}
            </MenuItem>
          </Menu>
        </div>
      )}
    </div>
  );
};

TrackerHistoryItem.propTypes = {
  record: PropTypes.object,
  onDelete: PropTypes.func,
};

export default TrackerHistoryItem;
