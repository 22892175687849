import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  addTrackingRecord,
  updateTrackingRecord,
} from '../actions/trackingRecordsActions';
import {
  MULTI_VALUE_MEASUREMENT_SUBTYPES,
  RELATIVE_PAGE_PATHS,
} from '../../src/utils/constants';
import TrackerForm from './tracker/TrackerForm';
import TrackerRecordNote from './tracker/TrackerRecordNote';
import { orderBy } from 'lodash';
import { selectTracker } from '../actions/trackerActions';
import { useHistory } from 'react-router-dom';
import TrackerHeader from './tracker/TrackerHeader';
import { MODAL_TYPE } from '../models/modalTypes';

const TrackScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { typeId, recordId } = useParams();
  const [modal, setModal] = useState(MODAL_TYPE.DEFAULT);
  const childBirthDate = useSelector(
    (state) => state.authentication.userData.childDateOfBirth
  );
  const tracker = useSelector((state) => {
    return (
      state.trackers.length && state.trackers.find((x) => `${x.id}` === typeId)
    );
  });

  const record = useSelector(
    (state) =>
      state.trackingRecords.length &&
      state.trackingRecords.find((x) => `${x.id}` === recordId)
  );

  useEffect(() => {
    dispatch(selectTracker(tracker));
    return () => dispatch(selectTracker(null));
  }, [tracker, dispatch]);

  const records = useSelector((state) => {
    return state.trackingRecords
      .filter((x) => `${x.subTypeId}` === typeId)
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .reverse();
  });

  const isMultiSelect = Object.values(
    MULTI_VALUE_MEASUREMENT_SUBTYPES
  ).includes(tracker.name);

  const saveRecord = ({ id, subTypeId, quantity, date, additionalInfo }) => {
    if (id) {
      dispatch(
        updateTrackingRecord(
          id,
          date,
          quantity,
          isMultiSelect ? additionalInfo : record.additionalInfo,
          record.notes
        )
      );
      history.goBack();
    } else
      dispatch(addTrackingRecord(subTypeId, date, quantity, additionalInfo));
  };

  const cancelRecord = () => {
    history.goBack();
  };

  const saveNote = (note) => {
    if (note) {
      dispatch(
        updateTrackingRecord(
          record.id,
          record.date,
          record.quantity,
          record.additionalInfo,
          note
        )
      );
      history.goBack();
    }
  };

  const deleteNote = () => {
    dispatch(
      updateTrackingRecord(
        record.id,
        record.date,
        record.quantity,
        record.additionalInfo,
        ''
      )
    );
    history.replace(`${RELATIVE_PAGE_PATHS.BREAST_FEEDING}`);
  };

  const openNote = (id) => {
    setModal(MODAL_TYPE.NOTE);
    history.push(`${RELATIVE_PAGE_PATHS.TRACKING}/${tracker.id}/${id}`);
  };

  const cancelNote = () => {
    setModal(MODAL_TYPE.DEFAULT);
    history.replace(`${RELATIVE_PAGE_PATHS.TRACKING}/${tracker.id}`);
  };

  if (tracker)
    return (
      <>
        <TrackerHeader
          childBirthDate={childBirthDate}
          tracker={tracker}
          lastRecord={records[0] || {}}
        />
        <TrackerForm
          tracker={tracker}
          records={records}
          record={record}
          onSave={saveRecord}
          onCancel={cancelRecord}
          onOpenNote={openNote}
          modalType={modal}
        />
        {modal === MODAL_TYPE.NOTE && (
          <TrackerRecordNote
            currentNote={record.notes}
            onSave={saveNote}
            onCancel={cancelNote}
            onDelete={deleteNote}
          />
        )}
      </>
    );
  else return <div>Missing tracker</div>;
};

export default TrackScreen;
