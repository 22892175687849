import { useEffect, useState} from 'react'
import {executeAddHomeIOS, executeAddHomeAndroid} from '../utils/executeAddHomePrompt'
import { useDispatch, useSelector } from 'react-redux';
import { ADD_EVENT_LISTENER } from '../actions/actionTypes';
import * as i18n from 'i18next';

export default function AddHomeScreen() {
    const dispatch = useDispatch();
    const eventList = useSelector(store => store.addToHomeReducer.beforeInstallPromptEvent)

    // Android
    useEffect(()=>{
        if(eventList){
            executeAddHomeAndroid(eventList)
        } else {
            window.addEventListener('beforeinstallprompt', (e) => {
                e.preventDefault()
                dispatch({ type: ADD_EVENT_LISTENER, payload: e })
                executeAddHomeAndroid(e)
            })
        }
    }, [])

    // IOS
    useEffect(() =>{
        const isIOSdevice = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.userAgent || navigator.vendor || navigator.platform);
        const isiPadDevice = (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
        if(isIOSdevice || isiPadDevice) {
            executeAddHomeIOS(isiPadDevice)
        }
    }, [])

  return (
    <div> 
        <h5 style={{marginTop: '10px'}}>{i18n.t('shared:addHomeScreenIcon')}</h5>
    </div>
  )
}
