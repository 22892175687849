import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import {
  adjustImageOrientation,
  fixTextOnImageSection,
  loadFontToJSPDF,
  nutinoFont,
  plainScriptFont,
  sectionBounding,
} from '.';
import jsPDF from 'jspdf';
import {
  getAxiosWithToken,
  getAxiosWithTokenNoErrorHandling,
} from '../../../utils/webApi';
import { useDispatch } from 'react-redux';
import {
  BIRTH_STICKER_NAME,
  FRONTEND_BASE_URL,
} from '../../../utils/constants';
import { showErrorModal, showSuccessModal } from '../../../actions';
import { isAndroid, isIOS, isMobile } from 'mobile-device-detect';

interface IDownloadPDFButton {
  isAdminPage: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  email?: string;
}

const DownloadPDFButton: React.FC<IDownloadPDFButton> = ({
  isAdminPage,
  email,
  setLoading,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleDownload = async () => {
    if (isAdminPage && email?.length === 0) {
      const errorMessage = t('paidFunctionalityScreen:errors.emptyField');
      dispatch(showErrorModal(errorMessage));

      return;
    }
    let response;

    setLoading(true);
    try {
      if (isAdminPage) {
        response = await getAxiosWithTokenNoErrorHandling().post(
          'journal/generate-for-admin',
          email
        );
      } else {
        response = await getAxiosWithToken().get(
          'journal/generate-for-download'
        );
      }
      requestJournalDownload(response);
    } catch (error: any) {
      if (
        (error.response && error.response.status === 400) ||
        error.response.status === 415
      ) {
        const errorMessage = t('paidFunctionalityScreen:errors.invalidUser');
        dispatch(showErrorModal(errorMessage));
      }
      setLoading(false);
    }
  };

  const requestJournalDownload = async (response: any) => {
    const returnedData = response.data;

    const pageFormat = [228, 464];
    if (returnedData) {
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: pageFormat,
      });
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = doc.internal.pageSize.getHeight();

      const hiddenContainer = document.createElement('div');
      hiddenContainer.style.position = 'absolute';
      hiddenContainer.style.top = '0';
      hiddenContainer.style.left = '-9999px';
      hiddenContainer.style.display = 'hidden';

      console.log(returnedData);

      hiddenContainer.innerHTML += returnedData.data.htmlContent;

      const coverPage = hiddenContainer.querySelector(
        '#start-part-2\\.1'
      ) as HTMLElement;
      const secondPageSpaceDiv = hiddenContainer.querySelector(
        '#start-part-2\\.2'
      ) as HTMLElement;

      if (coverPage && secondPageSpaceDiv) {
        coverPage.style.height = '464px';
        secondPageSpaceDiv.style.height = '31.2px';
      }

      document.body.appendChild(hiddenContainer);

      let sections = hiddenContainer.querySelectorAll('#section');

      const images = hiddenContainer.querySelectorAll('#user-image');
      const timeoutTime =
        images.length != 0 ? Number(images.length + sections.length + '0') : 10;

      // BIRTH STICKER PRIORITY
      const birthSticker =
        document.getElementsByClassName(BIRTH_STICKER_NAME)[0];
      console.log('birth sticker', birthSticker);
      const firstMonthSection = document.getElementsByClassName('month')[0];
      console.log('first month section', firstMonthSection);
      if (birthSticker && firstMonthSection) {
        firstMonthSection.insertAdjacentElement('afterend', birthSticker);
      }
      // BIRTH STICKER PRIORITY

      sections.forEach((section, index) => {
        const sectionElement = section as HTMLElement;

        const currentSection = sectionBounding(sectionElement, pdfHeight);

        if (
          currentSection.sectionIndexTop !== currentSection.sectionIndexBottom
        ) {
          const sectionText = sectionElement.querySelector(
            '#photo-text'
          ) as HTMLElement;

          const sectionBefore = sections[index - 1] as HTMLElement;
          const isBeforeMonth =
            sectionBefore.getAttribute('data')?.valueOf() == 'month-section';

          let state = {
            veryLongText: false,
            shouldCheckOnSecond: false,
          };
          let marginTop;

          const textMaxLength = isBeforeMonth ? 1000 : 1200;

          if (
            sectionText &&
            sectionText?.textContent?.length != undefined &&
            sectionText?.textContent?.length > textMaxLength
          ) {
            fixTextOnImageSection(
              sectionText,
              pdfHeight,
              sectionElement,
              state,
              currentSection
            );
          }

          if (state.veryLongText) {
            return;
          }

          if (isBeforeMonth) {
            const monthSectionBounding = sectionBounding(
              sectionBefore,
              pdfHeight
            );
            marginTop =
              pdfHeight * (monthSectionBounding.sectionIndexTop + 1) -
              monthSectionBounding.sectionTop +
              31.2;
            sectionBefore.style.marginTop = marginTop + 'px';
          } else {
            marginTop =
              pdfHeight * (currentSection.sectionIndexTop + 1) -
              currentSection.sectionTop +
              31.2;
            sectionElement.style.marginTop = marginTop + 'px';
          }

          if (state.shouldCheckOnSecond) {
            fixTextOnImageSection(
              sectionText,
              pdfHeight,
              sectionElement,
              state,
              currentSection
            );
          }
        }
      });

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      setTimeout(async function () {
        const adjustmentPromises = Array.from(images).map(
          (image: Element, index: number) =>
            adjustImageOrientation(image, canvas, ctx)
        );

        try {
          await Promise.all(adjustmentPromises);
        } catch (error) {
          console.error('Error adjusting image orientations:', error);
        }

        const x = (pdfWidth - 224) / 2;
        const y = (pdfHeight - 224) / 2 - 50;

        await loadFontToJSPDF(doc, plainScriptFont);

        doc.text(
          t('requestJournalDownloadScreen:firstYearText'),
          pdfWidth / 2,
          pdfHeight / 2 + 15,
          {
            align: 'center',
          }
        );
        doc.setFontSize(24);
        doc.text(
          `${returnedData.data.childName}`,
          pdfWidth / 2,
          pdfHeight / 2 + 32,
          {
            align: 'center',
          }
        );

        await loadFontToJSPDF(doc, nutinoFont);

        doc.addImage(
          `${FRONTEND_BASE_URL}system-images/CoverPage.png`,
          'PNG',
          x,
          y,
          224,
          224,
          'CoverPage',
          'NONE'
        );

        doc.addPage();

        // Create a new pdf and count the pages
        new jsPDF({
          orientation: 'portrait',
          unit: 'px',
          format: pageFormat,
        }).html(hiddenContainer.innerHTML, {
          callback: (res) => {
            //@ts-ignore
            let j = res.internal.getNumberOfPages();
            for (let i = 2; i <= j - 1; i++) {
              doc.addPage(pageFormat);
            }

            // PDf download
            doc.html(hiddenContainer.innerHTML, {
              autoPaging: 'text',
              callback: function () {
                doc.save(t('requestJournalDownloadScreen:pdfName') + '.pdf');
                const message = t(
                  'requestJournalDownloadScreen:successMessage'
                );
                dispatch(showSuccessModal(message));
                document.body.removeChild(hiddenContainer);
                setLoading(false);
              },
            });
          },
        });
      }, timeoutTime * 2);
    } else {
      setLoading(false);
    }
  };

  return (
    <Button
      className="greenBtn header next uppercase"
      onClick={handleDownload}
      disabled={isMobile || isAndroid || isIOS}
      translate="no"
    >
      {t('requestJournalDownloadScreen:submitButton')}
    </Button>
  );
};

export default DownloadPDFButton;
