import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { CANCELED, COMPLETE, OPEN } from '../../utils/constants';
import { showSubscribeModal, startLoader, stopLoader } from '../../actions';
import { STRIPE_PUBLIC_KEY } from '../../utils/constants';
import { getAxiosWithToken } from '../../utils/webApi';
import * as i18n from 'i18next';
import { Redirect } from 'react-router';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const CheckoutForm = ({ location }) => {
  const fetchClientSecret = useCallback(async () => {
    try {
      const response = await getAxiosWithToken().post('billing/create-checkout-session', {
        priceId: location.state.subscription.priceId,
        customerId: location.state.subscription.customer.id,
        isSubscriptionInRenewalPeriod: String(location.state.subscription.isSubscriptionInRenewalPeriod)
      });

      const { data } = response;
      return data.clientSecret;

    } catch (error) {
      console.error(`Error while processing the payment... \n${error}`);
    }
  }, [location]);

  const options = { fetchClientSecret };

  return (
    <div id="checkout" style={{ paddingBottom: '80px', width: '-webkit-fill-available', marginLeft: '-0.6rem', marginRight: '-0.6rem' }}>

      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
}

const Return = () => {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.authentication.userData.id);

  useEffect(() => {
    returnSessionStatusFromCheckout();
  }, []);

  const returnSessionStatusFromCheckout = async () => {
    dispatch(startLoader());

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    const priceId = urlParams.get('price_id');
    const customerId = urlParams.get('customer_id');
    const isSubscriptionInRenewalPeriod = urlParams.get('renewal');

    const { data } = await getAxiosWithToken()
      .get(`billing/session-status?session_id=${sessionId}&price_id=${priceId}&customer_id=${customerId}&renewal=${isSubscriptionInRenewalPeriod}&user_id=${userId}`);

    const status = data.status
    if (status) {
      switch (status) {
        case OPEN:
          dispatch(stopLoader());
          return <Redirect path='payments/checkout' />
        case COMPLETE:
          dispatch(stopLoader());
          return dispatch(showSubscribeModal(i18n.t('payments:successfulSubscription')));
        case CANCELED:
          dispatch(stopLoader());
          return dispatch(showSubscribeModal(i18n.t('payments:cancelledSubscription')));
        default:
          dispatch(stopLoader());
          console.error('Unhandled payment status: ' + status);
          return dispatch(showSubscribeModal(i18n.t('payments:defaultErrorMsg')));
      }
    }
  }

  return null;
}

export { CheckoutForm, Return };