import React from 'react';
import { IProductDialog } from '../IProductDialogInterface.interface';
import { Button, Modal, ModalHeader, ModalBody, Col } from 'reactstrap';
import { getAxiosWithToken } from '../../../../utils/webApi';
import { useDispatch } from 'react-redux';
import { startLoader, stopLoader } from '../../../../actions';
import { useTranslation } from 'react-i18next';
import { MODAL_DIALOG_STATE, useFormField } from '../utils';

const DeleteProductDialog: React.FC<IProductDialog> = ({
  currentCustomProductSelected,
  loadCategoryItems,
  modalDialogState,
  setModalDialogState,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { input: inputProduct } = useFormField('product');

  const onDelete = async () => {
    dispatch(startLoader());
    const response = await getAxiosWithToken().delete(
      `categoryitem/products/${currentCustomProductSelected?.id}`
    );
    dispatch(stopLoader());
    loadCategoryItems();
    setModalDialogState(MODAL_DIALOG_STATE.CLOSED);
    inputProduct.onChange('');
  };

  return (
    <div className="flexContainerCentered modal">
      <Modal
        isOpen={modalDialogState === MODAL_DIALOG_STATE.DELETE}
        toggle={() => setModalDialogState(MODAL_DIALOG_STATE.CLOSED)}
      >
        <ModalHeader
          className="modalHeader"
          toggle={() => setModalDialogState(MODAL_DIALOG_STATE.CLOSED)}
        />
        <ModalBody>
          <p className="flexContainerCentered centredText modalMsg">
            {t('trackingScreen:feeding.delete-product')}
          </p>
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={10}
            className="centred noPadding"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <Button
              className="greenBtn header danger secondary"
              onClick={() => setModalDialogState(MODAL_DIALOG_STATE.CLOSED)}
              style={{ width: '70%', margin: '10px 5px' }}
            >
              {t('modalDialog:cancel')}
            </Button>
            <Button
              className="redBtn header"
              onClick={() => onDelete()}
              style={{ width: '70%', margin: '10px 5px' }}
            >
              {t('modalDialog:continue')}
            </Button>
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DeleteProductDialog;
