import { useTranslation } from 'react-i18next';

const FeedingFooter: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div id="growth-footer">
      <div id="view-more">
        <p>{t('feedingAnalytics:footer.is-the-milk-enough')}</p>
        <a
          target="_blank"
          href="https://www.namama.bg/category/hranene-i-zdrave/" rel="noreferrer"
        >
          {t('feedingAnalytics:footer.view-more')}
        </a>
      </div>
    </div>
  );
};

export default FeedingFooter;