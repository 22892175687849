import { Stack, Typography } from '@mui/material';
import * as i18n from 'i18next';
import moment from 'moment';
import { useCallback, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { deleteTrackingRecord } from '../../actions/trackingRecordsActions';
import {
  MULTI_VALUE_MEASUREMENT_SUBTYPES,
  RELATIVE_PAGE_PATHS,
  TRACKERS_SUB_TYPE,
  foodWordsWithComma,
} from '../../utils/constants';
import formatMeasurement from '../../utils/trackerMeasurement';
import DateDivider from './DateDivider';
import TrackerHistoryItem from './TrackerHistoryItem';
import { useTranslation } from 'react-i18next';
import { TIME_OF_THE_DAY } from './tracking/models';
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized';

const TrackerHistory = ({
  historyRecords = [],
  hideInterval,
  requiresSpecialEditing,
  onEdit,
  onAdd,
  disableEdit,
  setModal,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('trackingScreen');

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
      // Height of the div (and top: px distance)
      // minHeight: 85,
    })
  );

  useEffect(() => {
    cache.current.clearAll(true);
  }, [historyRecords]);

  const HistoryTime = ({ record }) => {
    const recordDate = moment(record.date);
    let startDate, endDate;

    if (
      record.name === TRACKERS_SUB_TYPE.SLEEP ||
      record.name === TRACKERS_SUB_TYPE.CRY ||
      record.name === TRACKERS_SUB_TYPE.BREAST_FEEDING
    ) {
      startDate = recordDate.format('HH:mm');
      endDate = recordDate
        .clone()
        .add(record.quantity, 'seconds')
        .format('HH:mm');
    }

    return (
      <div className="interval-hours">
        {record.name === TRACKERS_SUB_TYPE.SLEEP ||
        record.name === TRACKERS_SUB_TYPE.CRY ||
        record.name === TRACKERS_SUB_TYPE.BREAST_FEEDING ? (
          <>
            <p className="start-end-hours">{endDate}</p>
            <p className="start-end-hours">{startDate}</p>
          </>
        ) : (
          moment(record.date).format('HH:mm')
        )}
      </div>
    );
  };

  const HistoryIndicator = () => {
    return (
      <div className="indicator pr-2 pl-2">
        <div className="line"></div>
        <div className="point"></div>
      </div>
    );
  };

  const getInterval = (record, nextRecord) => {
    if (!nextRecord) return 0;

    const duration =
      record.subType === TRACKERS_SUB_TYPE.SLEEP ||
      record.subType === TRACKERS_SUB_TYPE.CRY
        ? moment.duration(
            moment(record.date).diff(
              moment(nextRecord.date).add(
                nextRecord.quantity * 1000,
                'milliseconds'
              )
            )
          )
        : moment.duration(moment(record.date).diff(moment(nextRecord.date)));

    const months =
      duration.months() === 0
        ? ''
        : t('trackingHistory:months', { months: duration.months() });
    const days =
      duration.days() === 0
        ? ''
        : t('trackingHistory:days', { days: duration.days() });

    const hours =
      duration.hours() === 0
        ? ''
        : t('trackingHistory:hours', { hours: duration.hours() });

    const minutes =
      duration.minutes() === 0
        ? ''
        : t('trackingHistory:minutes', { min: duration.minutes() });

    const interval =
      months === '' ? `${days} ${hours} ${minutes}` : `${months} ${days}`;

    if (interval.trim() === '') return '';

    const timeUnitsArray = interval.split(' ').filter((value) => value !== '');

    if (timeUnitsArray.length === 1) return timeUnitsArray[0];

    timeUnitsArray.splice(
      timeUnitsArray.length - 1,
      0,
      t('trackingHistory:and')
    );

    return timeUnitsArray.join(' ');
  };

  const getNextRecord = (recordId, historyRecord) => {
    const updatedRecords = historyRecord.slice(recordId + 1);
    const nextRecord = updatedRecords.find(
      (record) => record.additionalInfo !== TIME_OF_THE_DAY.LULL
    );
    return nextRecord;
  };

  const HistoryDetails = ({ record, nextRecord }) => {
    const isLull =
      record.additionalInfo === TIME_OF_THE_DAY.LULL ? true : false;
    let intervalKey;
    switch (record.subType) {
      case TRACKERS_SUB_TYPE.SLEEP:
        intervalKey = TRACKERS_SUB_TYPE.SLEEP;
        break;
      case MULTI_VALUE_MEASUREMENT_SUBTYPES.MEDICINE:
        // Handle your next sub-type
        intervalKey = MULTI_VALUE_MEASUREMENT_SUBTYPES.MEDICINE;
        break;
      // Add more cases for additional sub-types as needed
      default:
        intervalKey = 'interval';
        break;
    }
    return (
      <div className="pl-1 pr-1">
        <Stack>
          {record.subType != MULTI_VALUE_MEASUREMENT_SUBTYPES.FEEDING ? (
            <span>
              {formatMeasurement(
                record.quantity,
                record.symbol,
                record.type,
                record.subType,
                record.additionalInfo,
                false
              )}
            </span>
          ) : (
            FormatFeedingMeasurement(record.quantity, record.additionalInfo)
          )}
          <div className="tracker-record-note">{record.notes}</div>
          {!hideInterval && nextRecord && !isLull && (
            <Typography component="span" fontSize={'12px'}>
              {t(`history.${intervalKey}`, {
                interval: getInterval(record, nextRecord),
              })}
            </Typography>
          )}
        </Stack>
      </div>
    );
  };

  const handleDelete = useCallback(
    (id) => {
      dispatch(deleteTrackingRecord(id));
    },
    [dispatch]
  );

  const handleEdit = (id, subTypeId) => {
    if (requiresSpecialEditing) {
      onEdit && onEdit(id);
    } else {
      history.push(`${RELATIVE_PAGE_PATHS.TRACKING}/${subTypeId}/${id}`);
    }
  };

  const handleAddNote = (id) => {
    onAdd && onAdd(id);
  };

  return (
    <div style={{ width: '100%' }}>
      <Typography variant={'h5'} textAlign={'center'}>
        {i18n.t('breastfeedingScreen:history')}
      </Typography>
      <div className="history" style={{ height: '100vh' }}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              width={width}
              rowCount={historyRecords.length}
              rowHeight={cache.current.rowHeight}
              deferredMeasurementCache={cache.current}
              rowRenderer={({ key, index, style, parent }) => (
                <CellMeasurer
                  key={key}
                  cache={cache.current}
                  parent={parent}
                  columnIndex={0}
                  rowIndex={index}
                >
                  <div style={style}>
                    <DateDivider
                      current={historyRecords[index]}
                      previous={historyRecords[index - 1]}
                    />
                    <TrackerHistoryItem
                      record={historyRecords[index]}
                      onEdit={handleEdit}
                      onDelete={handleDelete}
                      onAdd={handleAddNote}
                      disableEdit={disableEdit}
                      disableLink={requiresSpecialEditing}
                      setModal={setModal}
                    >
                      <HistoryTime record={historyRecords[index]} />
                      <HistoryIndicator />
                      <HistoryDetails
                        record={historyRecords[index]}
                        nextRecord={getNextRecord(index, historyRecords)}
                      />
                    </TrackerHistoryItem>
                  </div>
                </CellMeasurer>
              )}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

const FormatFeedingMeasurement = (quantity, additionalInfo) => {
  const {
    symbol: newProductSymbol,
    product: newProduct,
    productForm: productForm,
    totalProduct: ingredientsArray,
    totalQuantity: ingredientsQuantity,
    totalSymbol: ingredientsSymbol,
  } = JSON.parse(additionalInfo);

  let productFormText = '';
  let ingredientsText = '';
  let newFoodString = '';

  if (productForm) {
    productFormText += `${i18n
      .t(`trackingScreen:foodForm.${productForm}`)
      .toLowerCase()}`;

    if (newProduct || ingredientsArray.length > 0) {
      if (foodWordsWithComma.includes(productForm)) {
        ingredientsText += ', ';
      } else {
        ingredientsText += ` ${i18n.t('trackingHistory:with')} `;
      }
    }
  } else {
    productFormText += `${i18n
      .t('trackingScreen:trackers.feeding')
      .toLowerCase()}`;
  }

  if (ingredientsQuantity && ingredientsSymbol) {
    ingredientsText += ` ${i18n.t(
      'trackingHistory:total'
    )} ${ingredientsQuantity} ${i18n.t(
      `trackingScreen:units.${ingredientsSymbol}`
    )} `;
  }

  if (ingredientsArray) {
    const translatedIngredientsString = ingredientsArray
      .map((e) => {
        const [ingredientName, ingredientCategory] = e.split(':');
        // Check if the product is custom
        if (e.startsWith('multi-select')) {
          return i18n.t(`trackingScreen:feeding.${ingredientName}`);
        } else {
          return ingredientName;
        }
      })
      .join(', ');
    ingredientsText += ` ${translatedIngredientsString}`;
  }

  if (newProduct) {
    if (ingredientsArray) {
      ingredientsText += ` ${i18n.t('trackingHistory:and')}`;
    }

    let newProductTranslation = '';
    if (newProduct.startsWith('multi-select')) {
      newProductTranslation = i18n.t(`trackingScreen:feeding:${newProduct}`);
    } else {
      newProductTranslation = newProduct;
    }
    newFoodString += ` ${i18n
      .t('trackingScreen:feeding:added-new-product')
      .toLowerCase()}: ${newProductTranslation}`;

    if (newProductSymbol && quantity) {
      newFoodString += `, ${quantity} ${i18n.t(
        `trackingScreen:units.${newProductSymbol}`
      )}`;
    }
  }
  return (
    <span>
      <span className="product-text">{productFormText}</span>
      <span className="ingredients-text">{ingredientsText}</span>
      <span className="new-food-text">{newFoodString}</span>
    </span>
  );
};

export default TrackerHistory;
