import { Button, Typography } from '@mui/material';
import i18n from 'i18next';
import { useCallback } from 'react';
import { useTrackingTimer } from '../../../context/TrackingTimerProvider';

import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal, showConfirmModal } from '../../../actions';
import { TIMER_STATE } from '../../../models/timerStatuses';
import RouterLink from '../../common/RouterLink';
import { TimerVisualizer } from '../shared/TimerVisualizer';
import { LeftButton } from './LeftButton';
import { RightButton } from './RightButton';
import { RELATIVE_PAGE_PATHS } from '../../../utils/constants';
import { MODAL_TYPE } from '../../../models/modalTypes';

interface TrackingTimerProps {
  onSave?: (data: {
    startTime: Date;
    duration: number;
    additionalInfo: string;
  }) => void;
  tracker: string;
}

export const TrackingTimer = ({ tracker, onSave }: TrackingTimerProps) => {
  const {
    start,
    stop,
    reset,
    isWorking,
    timerState: timerStatus,
    getDuration,
    value,
    additionalInfo,
    activeSide,
    startTime,
    isActiveTracker,
    activeTracker,
  } = useTrackingTimer(tracker);
  const dispatch = useDispatch();

  const isClearButtonEnabled = isActiveTracker && !isWorking && getDuration();
  const isSaveButtonEnabled =
    isActiveTracker && timerStatus !== TIMER_STATE.PLAY && getDuration();

  const clear = useCallback(() => {
    reset();
  }, [reset]);

  const handleClearButtonClick = () => {
    if (isClearButtonEnabled) {
      clear();
    }
  };

  const handleSave = useCallback(() => {
    if (startTime && activeSide) {
      onSave?.({
        startTime,
        duration: getDuration(),
        additionalInfo,
      });
      clear();
    }
  }, [activeSide, additionalInfo, getDuration, onSave, startTime, clear]);

  const startStopHanlder = useCallback(
    (value: string) => {
      if (timerStatus === TIMER_STATE.PLAY && activeSide !== value) {
        return;
      }
      if (isWorking) {
        stop();
      }

      if (!isWorking) {
        if (isClearButtonEnabled) {
          dispatch(
            showConfirmModal({
              message: 'Искате ли да запазите предишното засичане?',
              onConfirm: () => {
                handleSave();
                start(value);
                dispatch(closeModal());
              },
              onDeny: () => {
                start(value);
                dispatch(closeModal());
              },
            })
          );
        } else {
          start(value);
        }
      }
    },
    [
      activeSide,
      isWorking,
      isClearButtonEnabled,
      start,
      stop,
      timerStatus,
      dispatch,
      handleSave,
    ]
  );
  return (
    <>
      {isActiveTracker ? (
        <TimerVisualizer value={value} />
      ) : (
        <Typography className="mt-3">
          <Trans
            i18nKey={'trackingScreen:tracking.active-tracker'}
            values={{
              trackerName: i18n
                .t(`trackingScreen:trackers.${activeTracker}`)
                .toString()
                .toLowerCase(),
            }}
            components={{
              strong: <RouterLink setModal={tracker === RELATIVE_PAGE_PATHS.BREAST_FEEDING ? MODAL_TYPE.EDIT : MODAL_TYPE.DEFAULT} to={`/tracking/${activeTracker}`} />,
            }}
          />
        </Typography>
      )}
      <div className="breastfeeding-buttons">
        <div className="w-50">
          <LeftButton
            tracker={tracker}
            selectedSide={activeSide}
            isActive={isWorking}
            onClick={startStopHanlder}
          />
          <div className="p-3 d-flex justify-content-center">
            <Button
              color="error"
              disabled={!isClearButtonEnabled}
              variant="contained"
              onClick={handleClearButtonClick}
            >
              {i18n.t('sleepTrackerScreen:buttons.clear') + ''}
            </Button>
          </div>
        </div>
        <div className="w-50">
          <RightButton
            tracker={tracker}
            selectedSide={activeSide}
            isActive={isWorking}
            onClick={startStopHanlder}
          />
          <div className="p-3 d-flex justify-content-center">
            <Button
              disabled={!isSaveButtonEnabled}
              variant="contained"
              onClick={handleSave}
            >
              {i18n.t('sleepTrackerScreen:buttons.save') + ''}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
