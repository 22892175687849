import {
  APPROXIMATE_DAYS_PER_MONTH_WHO,
  CHART_OPTIONS,
  CHART_TYPE_OPTIONS,
  DAYS_AFTER_LAST_RECORD,
  DAYS_PER_MONTH_WHO,
  TRACKER_SUBTYPES,
  TWO_YEARS,
  USER_DATA_IN_LOCAL_STORAGE,
  WEIGHT_FOR_LENGTH_RECORDS,
  WEIGHT_FOR_LENGTH_RECORD_NUMBER,
} from '../../../utils/constants';
import {
  IGraphData,
  IGraphDataWeightForLength,
  IPercentileDaysData,
  ISubjectArrayValues,
  ITrackerDataSimplified,
} from './IGrowthCurves.interface';
import i18next from 'i18next';
import moment, { Moment } from 'moment';

export const getPercentilesForCategory = (
  categoryObjects: IPercentileDaysData,
  maxDays: number
): ISubjectArrayValues => {
  const daysForPeriod = Array(maxDays).fill(0);
  for (let i = 0; i < maxDays; i++) {
    daysForPeriod[i] = i;
  }

  return {
    days: daysForPeriod,
    percentile3rdValues: categoryObjects.percentile3rd.slice(0, maxDays + 1),
    percentile15thValues: categoryObjects.percentile15th.slice(0, maxDays + 1),
    percentile50thValues: categoryObjects.percentile50th.slice(0, maxDays + 1),
    percentile85thValues: categoryObjects.percentile85th.slice(0, maxDays + 1),
    percentile97thValues: categoryObjects.percentile97th.slice(0, maxDays + 1),
  };
};

export const getPercentilesWeightForLength = (
  categoryObjects: IPercentileDaysData
): ISubjectArrayValues => {
  return {
    days: WEIGHT_FOR_LENGTH_RECORDS,
    percentile3rdValues: categoryObjects.percentile3rd,
    percentile15thValues: categoryObjects.percentile15th,
    percentile50thValues: categoryObjects.percentile50th,
    percentile85thValues: categoryObjects.percentile85th,
    percentile97thValues: categoryObjects.percentile97th,
  };
};

export const getValuesForTracker = (
  trackerRecords: any[],
  trackerName: string
): ITrackerDataSimplified[] => {
  const userData = localStorage.getItem(USER_DATA_IN_LOCAL_STORAGE);
  const childDateOfBirth = new Date(JSON.parse(userData!).childDateOfBirth);
  const childDateMoment = moment(childDateOfBirth);

  const daysData = Array(TWO_YEARS).fill(null);

  const trackerDataSimplified = trackerRecords
    .filter((record) => record.subType === trackerName)
    .filter((record) => new Date(record.date) > childDateOfBirth)
    //@ts-ignore
    .sort((a, b) => new Date(a.date) - new Date(b.date))
    .map((record) => {
      let quantity;
      switch (trackerName) {
        case TRACKER_SUBTYPES.WEIGHT:
          quantity = (record.quantity / 1000).toFixed(2);
          break;
        default:
          quantity = record.quantity;
          break;
      }
      return { date: new Date(record.date), value: quantity };
    });

  trackerDataSimplified.forEach((record) => {
    const recordDate = moment(record.date);
    const daysDiff = recordDate.diff(childDateMoment, 'days');

    daysData[daysDiff] = record;
  });

  return daysData.slice(
    0,
    findLastNonNullIndex(daysData) + DAYS_AFTER_LAST_RECORD
  );
};

export const getPairsForWeightLength = (
  weightData: ITrackerDataSimplified[],
  heightData: ITrackerDataSimplified[]
) => {
  const matchingPairs = Array(WEIGHT_FOR_LENGTH_RECORD_NUMBER).fill(null);
  for (let i = 0; i < heightData.length; i++) {
    if (weightData[i] && heightData[i]) {
      matchingPairs[heightData[i].value - 45] = {
        date: heightData[i].date,
        value: weightData[i].value,
        height: heightData[i].value,
      };
    }
  }

  return matchingPairs;
};

export const getOptionsForGrowth = (
  categoryData: IGraphData,
  measurement: string,
  yAxisLabel: string,
  childName: string,
  chartType: string
) => {
  const options = {
    ...CHART_OPTIONS.HEIGHT,
    xAxis: {
      name: i18next.t('growthCurves:age(months)'),
      data: categoryData.WHOcategoryData.days,
      interval: 30,
      axisLabel: {
        hideOverlap: true,
        show: true,
        interval: (number: any) => {
          //@ts-ignore
          const monthNumber = APPROXIMATE_DAYS_PER_MONTH_WHO[number];
          if (monthNumber) {
            return monthNumber;
          }
        },
        formatter: (number: number) => {
          //@ts-ignore
          return `${APPROXIMATE_DAYS_PER_MONTH_WHO[number]}`;
        },
      },
      ...CHART_OPTIONS.X_AXIS,
    },
    yAxis: {
      name: yAxisLabel,
      //@ts-ignore
      interval: CHART_TYPE_OPTIONS[chartType].interval,
      //@ts-ignore
      min: CHART_TYPE_OPTIONS[chartType].min,
      ...CHART_OPTIONS.Y_AXIS,
    },
    grid: CHART_OPTIONS.GRID,
    tooltip: {
      ...CHART_OPTIONS.TOOLTIP,
      formatter: (params: any) => {
        // Shot tooltip only for child line
        if (params.seriesName === childName) {
          return `
        <div class="tooltip-container"> 
          <p id="tooltip-date">
            ${params.data.date.getDate()}.${params.data.date.getMonth() + 1
            }.${params.data.date.getFullYear()}
          </p>
          <p class="tooltip-text">
            <span>${i18next.t('growthCurves:age')}: </span>
            <span class="tooltip-value">${convertDaysToMonthsAndDays(
              params.dataIndex
            )} </span> 
          </p>
          <p class="tooltip-text">
            <span>${i18next.t(`growthCurves:chart.${chartType}`)}: </span>
            <span class="tooltip-value">${params.value}${measurement}</span>
          </p>
        </div>`;
        }
      },
    },
    dataZoom: CHART_OPTIONS.DATAZOOM,
    series: [
      {
        name: childName,
        data: categoryData.childData,
        ...CHART_OPTIONS.SERIES.CHILD,
      },
      {
        ...CHART_OPTIONS.SERIES.P3,
        data: categoryData.WHOcategoryData.percentile3rdValues,
      },
      {
        data: categoryData.WHOcategoryData.percentile15thValues,
        ...CHART_OPTIONS.SERIES.P15,
      },
      {
        data: categoryData.WHOcategoryData.percentile50thValues,
        ...CHART_OPTIONS.SERIES.P50,
      },
      {
        data: categoryData.WHOcategoryData.percentile85thValues,
        ...CHART_OPTIONS.SERIES.P85,
      },
      {
        data: categoryData.WHOcategoryData.percentile97thValues,
        ...CHART_OPTIONS.SERIES.P97,
      },
    ],
  };

  return options;
};

export const getOptionsWeightForLength = (
  categoryData: IGraphDataWeightForLength,
  measurement: string,
  yAxisLabel: string,
  childName: string,
  chartType: string
) => {
  const options = {
    ...CHART_OPTIONS.HEIGHT,
    xAxis: {
      name: i18next.t('growthCurves:height(unit)'),
      data: categoryData.WHOcategoryData.days,
      axisLabel: {
        hideOverlap: true,
        show: true,
      },
      ...CHART_OPTIONS.X_AXIS,
    },
    yAxis: {
      name: yAxisLabel,
      //@ts-ignore
      interval: CHART_TYPE_OPTIONS[chartType].interval,
      //@ts-ignore
      min: CHART_TYPE_OPTIONS[chartType].min,
      ...CHART_OPTIONS.Y_AXIS,
    },
    grid: CHART_OPTIONS.GRID,
    tooltip: {
      ...CHART_OPTIONS.TOOLTIP,
      formatter: (params: any) => {
        // Shot tooltip only for child line
        if (params.seriesName === childName) {
          return `
        <div class="tooltip-container">
          <p id="tooltip-date">
            ${params.data.date.getDate()}.${params.data.date.getMonth() + 1
            }.${params.data.date.getFullYear()}
          </p>
          <p class="tooltip-text">
            <span>${i18next.t('growthCurves:chart.height')}:</span>
            <span class="tooltip-value">${params.data.height}${i18next.t(
              'growthCurves:units.cm'
            )} </span>
          </p>
          <p class="tooltip-text">
            <span>${i18next.t('growthCurves:chart.weight')}:</span>
            <span class="tooltip-value"> ${params.value}${measurement} </span>
          </p> 
        </div>`;
        }
      },
    },
    dataZoom: CHART_OPTIONS.DATAZOOM,
    series: [
      {
        name: childName,
        data: categoryData.childData,
        ...CHART_OPTIONS.SERIES.CHILD,
      },
      {
        ...CHART_OPTIONS.SERIES.P3,
        data: categoryData.WHOcategoryData.percentile3rdValues,
      },
      {
        data: categoryData.WHOcategoryData.percentile15thValues,
        ...CHART_OPTIONS.SERIES.P15,
      },
      {
        data: categoryData.WHOcategoryData.percentile50thValues,
        ...CHART_OPTIONS.SERIES.P50,
      },
      {
        data: categoryData.WHOcategoryData.percentile85thValues,
        ...CHART_OPTIONS.SERIES.P85,
      },
      {
        data: categoryData.WHOcategoryData.percentile97thValues,
        ...CHART_OPTIONS.SERIES.P97,
      },
    ],
  };

  return options;
};

export function convertDaysToMonthsAndDays(days: number) {
  const months = Math.floor(days / DAYS_PER_MONTH_WHO);
  const remainingDays = days % DAYS_PER_MONTH_WHO;
  const roundedDays = Math.round(remainingDays);

  let result = '';
  if (months > 0) {
    result += `${months}${i18next.t('growthCurves:units.month')}. `;
  }
  if (roundedDays > 0) {
    result += `${roundedDays}${i18next.t('growthCurves:units.days')}.`;
  }

  return result;
}

export const getChildBirthDate = (): Moment => {
  const userData = localStorage.getItem(USER_DATA_IN_LOCAL_STORAGE);
  const childDateOfBirth = new Date(JSON.parse(userData!).childDateOfBirth);
  const childDateMoment = moment(childDateOfBirth);

  return childDateMoment;
};

export const calculateChildAge = (currentDate: Moment): string => {
  const childBirthDate = getChildBirthDate();
  const years = currentDate.diff(childBirthDate, 'years');
  const months = currentDate.diff(childBirthDate.add(years, 'years'), 'months');
  const adjustedBirth = childBirthDate.clone().add(months, 'months');
  const totalMonths = (years * 12) + months;
  const days = currentDate.diff(adjustedBirth, 'days');

  let childAge = '';
  if (totalMonths > 0) {
    childAge += `${totalMonths}${i18next.t('growthCurves:units.month')}. `;
  }
  if (days > 0) {
    childAge += `${days}${i18next.t('growthCurves:units.days')}.`;
  }

  return childAge;
};

function findLastNonNullIndex(array: any[]) {
  for (let i = array.length - 1; i >= 0; i--) {
    if (array[i] !== null) {
      return i;
    }
  }
  return array.length;
}
