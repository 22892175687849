import { useState, useContext, useEffect } from 'react';
import SleepTable from './components/SleepTable';
import { RELATIVE_PAGE_PATHS, TRACKER_SUBTYPES } from '../../utils/constants';
import { ITrackerRecord } from '../../interfaces/ITrackerRecord.interface';
import {
  ISleepTable,
  ISleepTableData,
  ITableTimeRemainder,
} from './helper/ISleepAnalytics.interface';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { IUser } from '../../interfaces/IUser.interface';
import SleepCalendar from './components/SleepCalendar';
import BirthInfo from './components/BirthInfo';
import TrackerSeparator from './components/TrackerSeparator';
import SleepFooter from './components/SleepFooter';
import {
  formatNumber,
  getSleepTableRowDataForDay,
  separateRecordsForPeriod,
} from './helper/sleepUtils';
import DatePickerCalendar from './components/DatePickerCalendar';
import { DatesContext } from './components/DateProvider';
import TableEmptyState from './components/TableEmptyState';

interface ISleepAnalytics {
  trackerRecords: ITrackerRecord[];
  currentUser: IUser;
}

const SleepAnalytics: React.FC<ISleepAnalytics> = ({
  trackerRecords,
  currentUser,
}) => {
  const [sleepTableData, setSleepTableData] = useState<ISleepTable>();
  const [separatedDays, setSeparatedDays] = useState<ITrackerRecord[][]>([[]]);
  const { startDate, endDate } = useContext(DatesContext);

  const { t } = useTranslation();

  const handleSearch = (startDate: Date, endDate: Date) => {
    const sleepRecords = trackerRecords
      .filter(
        (record) =>
          record.subType === TRACKER_SUBTYPES.SLEEP &&
          new Date(record.date) >= new Date(currentUser?.childDateOfBirth)
      )
      //@ts-ignore
      .sort((a, b) => new Date(a.date) - new Date(b.date));
    if (sleepRecords.length > 0) {
      const separatedDays = separateRecordsForPeriod(
        startDate,
        endDate,
        sleepRecords
      );
      setSeparatedDays(separatedDays);

      const tableData: ISleepTableData[] = [];
      let accumulatedMinutesForNextDay: ITableTimeRemainder = {
        action: '',
        remainder: 0,
      };
      separatedDays.forEach((currentDayRecords, i) => {
        const tableDataForDay = getSleepTableRowDataForDay(
          currentDayRecords,
          separatedDays[i - 1],
          accumulatedMinutesForNextDay
        );
        tableData.push(tableDataForDay.currentRowData);
        accumulatedMinutesForNextDay = tableDataForDay.timeRemainder;
      });

      const periodLengthDays =
        moment(endDate).diff(moment(startDate), 'days') + 1;

      const averageTableData: ISleepTableData = tableData.reduce(
        (acc, tableRecord) => {
          return {
            dateAndAge: acc.dateAndAge,
            nighttimeSleep:
              acc.nighttimeSleep +
              tableRecord.nighttimeSleep / periodLengthDays,
            daytimeSleep:
              acc.daytimeSleep + tableRecord.daytimeSleep / periodLengthDays,
            totalSleep:
              acc.totalSleep + tableRecord.totalSleep / periodLengthDays,
            totalAwakening: formatNumber(
              acc.totalAwakening + tableRecord.totalAwakening / periodLengthDays
            ),
            daytimeNaps: formatNumber(
              acc.daytimeNaps + tableRecord.daytimeNaps / periodLengthDays
            ),
            nightWaking: formatNumber(
              acc.nightWaking + tableRecord.nightWaking / periodLengthDays
            ),
            lull: formatNumber(acc.lull + tableRecord.lull / periodLengthDays),
          };
        },
        {
          dateAndAge: t('sleepAnalytics:periodAverage'),
          nighttimeSleep: 0,
          daytimeSleep: 0,
          totalSleep: 0,
          totalAwakening: 0,
          daytimeNaps: 0,
          nightWaking: 0,
          lull: 0,
        }
      );

      setSleepTableData({
        tableData: tableData,
        averageData: averageTableData,
      });
    }
  };

  useEffect(() => {
    if (endDate) {
      handleSearch(startDate, endDate);
    }
  }, [trackerRecords, endDate]);

  return (
    <div>
      <h5 className="primaryHeader">
        <p>{t('sleepAnalytics:title-sleep')} </p>
        <span>
          {t('sleepAnalytics:to')} {currentUser?.childName.toUpperCase()}
        </span>
      </h5>
      <BirthInfo />
      <div id="sleep">
        <DatePickerCalendar />
        <div id="tracker-separator-sleep">
          <TrackerSeparator
            trackerLink={RELATIVE_PAGE_PATHS.SLEEP}
            trackerIcon={'sleep.svg'}
            trackerType={TRACKER_SUBTYPES.SLEEP}
          />
        </div>
        {sleepTableData && sleepTableData.tableData.length > 0 ? (
          <>
            <div id="sleep-section">
              <div>
                <SleepCalendar
                  period={{ startDate, endDate }}
                  data={separatedDays}
                />
              </div>
            </div>
            <div id="sleep-table">
              <SleepTable
                data={sleepTableData.tableData}
                footer={sleepTableData.averageData}
              />
            </div>
          </>
        ) : (
          <TableEmptyState />
        )}
        <SleepFooter />
      </div>
    </div>
  );
};

export default SleepAnalytics;
