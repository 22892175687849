import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useField } from 'react-final-form';
import { breasts } from '../../../models/breasts';

export interface BreastFieldProps {
  name: string;
}
export const BreastField = ({ name }: BreastFieldProps) => {
  const { input } = useField(name);
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="breast"
        row
        value={input.value}
        onBlur={input.onBlur}
        onChange={(e) => input.onChange(e.target.value)}
      >
        <Box
          display="flex"
          gridTemplateColumns={'1fr 1fr'}
          flexWrap="wrap"
          columnGap={1}
        >
          <FormControlLabel
            value={breasts.left}
            control={<Radio />}
            label="Лява"
          />
          <FormControlLabel
            value={breasts.right}
            control={<Radio />}
            label="Дясна"
          />
          <FormControlLabel
            value={breasts.both}
            control={<Radio />}
            label="И от двете"
          />
        </Box>
      </RadioGroup>
    </FormControl>
  );
};
