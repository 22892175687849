import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'
import bg from './translations/bg.json';

let resourceFiles = {
  bg: bg,
  // fr: {
  //   'shared': {
  //     'language': 'Langue',
  //   }
  // }
};

i18next.use(LanguageDetector).use(initReactI18next).init({
  interpolation: { escapeValue: false },
  resources: resourceFiles,
  fallbackLng: 'bg', //Default language
  // debug: true,
});

export default i18next;

//Used for selector component
export const languages = [
  // {
  //   code: 'fr',
  //   name: 'Français',
  //   country_code: 'fr',
  // },
  {
    code: 'bg',
    name: 'Български',
    country_code: 'bg',
  },
]
