import * as i18n from 'i18next';
import moment from 'moment';
import {
  ADD_STICKER_IN_LOCAL_STORAGE,
  FRONTEND_BASE_URL,
  RELATIVE_PAGE_PATHS,
  SELECTED_GOAL_IN_LOCAL_STORAGE,
  SELECTED_STICKER_IN_LOCAL_STORAGE,
} from '../utils/constants';
import navigationUtils from '../utils/navigationUtils';
import { getAxiosWithToken } from '../utils/webApi';
import {
  ADD_STICKER_TO_JOURNAL_SUCCESS,
  CLEAR_MODAL_STATE,
  CLEAR_REDUCERS,
  CLEAR_SELECTED_TAG,
  HIDE_HAMBURGER_MENU,
  SELECT_GOAL_SUCCESS,
  SELECT_STICKER_SUCCESS,
  SHOW_AGREEMENT_MODAL,
  SHOW_CONFIRM_DIALOG,
  SHOW_RED_CONFIRM_DIALOG,
  SHOW_DELETE_MODAL,
  SHOW_ERROR_MODAL,
  SHOW_HAMBURGER_MENU,
  SHOW_INFO_MODAL,
  SHOW_SHARE_MODAL,
  SHOW_SUCCESS_MODAL,
  START_LOADER,
  START_NAV_LOADER,
  STOP_LOADER,
  STOP_NAV_LOADER,
  SHOW_PAID_PRODUCTS_MODAL,
  SHOW_SUBSCRIBE_MODAL
} from './actionTypes';

export const clearReducers = () => {
  return { type: CLEAR_REDUCERS };
};

export const showHamburgerMenu = () => {
  return { type: SHOW_HAMBURGER_MENU };
};

export const hideHamburgerMenu = () => async (dispatch, getStore) => {
  if (getStore().common.showHamburgerMenu === 'SHOW') {
    dispatch({ type: HIDE_HAMBURGER_MENU });
  }
  dispatch({ type: STOP_NAV_LOADER });
};

export const showSuccessModal = (message, redirect) => openModal(SHOW_SUCCESS_MODAL, message, redirect);

export const showShareModal = (message) => openModal(SHOW_SHARE_MODAL, message);

export const showInfoModal = (message) => openModal(SHOW_INFO_MODAL, message);

export const showConfirmModal = (props) => openModal(SHOW_CONFIRM_DIALOG, props);

export const showRedConfirmModal = (props) => openModal(SHOW_RED_CONFIRM_DIALOG, props);

export const showConfirmationModal = (message) => openModal(SHOW_AGREEMENT_MODAL, message);

export const showPaidProductsModal = (message) => openModal(SHOW_PAID_PRODUCTS_MODAL, message);

export const showSubscribeModal = (message) => openModal(SHOW_SUBSCRIBE_MODAL, message);

export const showErrorModal = (message) => openModal(SHOW_ERROR_MODAL, message);

export const showDeleteModal = (props) => openModal(SHOW_DELETE_MODAL, props);


export const openModal = (modalType, message, redirect) => {
  return redirect
    ? { type: modalType, payload: message, redirect: redirect }
    : { type: modalType, payload: message };
};

export const closeModal = () => {
  return (dispatch, getState) => {
    if (getState().modal.redirect) {
      navigationUtils.navigate(RELATIVE_PAGE_PATHS.HOME);
    }

    dispatch({ type: CLEAR_MODAL_STATE });
  };
};

export const selectTag = (selectedGoal, selectedTag) => {
  const selectedGoalLocalStorage = selectedGoal
    ? selectedGoal
    : JSON.parse(localStorage.getItem(SELECTED_GOAL_IN_LOCAL_STORAGE));

  return (dispatch) => {
    dispatch({
      type: SELECT_GOAL_SUCCESS,
      payload: selectedGoalLocalStorage,
    });
    navigationUtils.navigate(RELATIVE_PAGE_PATHS.SELECTED_TAG);
  };
};

export const selectSticker = (selectedSticker) => {
  return (dispatch) => {
    dispatch({ type: SELECT_STICKER_SUCCESS, payload: selectedSticker });
    localStorage.setItem(
      SELECTED_STICKER_IN_LOCAL_STORAGE,
      JSON.stringify(selectedSticker)
    );
    navigationUtils.navigate(RELATIVE_PAGE_PATHS.ADD_STICKER);
  };
};

export const addSticker = (selectedSticker) => {
  const sticker = selectedSticker
    ? selectedSticker
    : JSON.parse(localStorage.getItem(ADD_STICKER_IN_LOCAL_STORAGE));

  return (dispatch) => {
    dispatch({ type: ADD_STICKER_TO_JOURNAL_SUCCESS, payload: sticker });
    navigationUtils.navigate(RELATIVE_PAGE_PATHS.ADD_STICKER);
  };
};

export const clearSelectTag = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_SELECTED_TAG });
  };
};

export const startLoader = () => {
  return { type: START_LOADER };
};

export const stopLoader = () => async (dispatch) => {
  setTimeout(() => {
    dispatch({ type: STOP_LOADER });
  }, 350);
};

export const startNavLoader = () => {
  return { type: START_NAV_LOADER };
};

export const stopNavLoader = () => async (dispatch) => {
  setTimeout(() => {
    dispatch({ type: STOP_NAV_LOADER });
  }, 350);
};

export const checkForNewVersion = () => async (dispatch) => {
  /*
   * This skips the new version check and allows local testing of project.
   * Without this the request will be blocked by CORS policy.
   * On DEV we don't have https requests
   */
  const isRequestSecure = window.location.href.indexOf('https://') === 0;

  if (!isRequestSecure) {
    return;
  }

  const serviceWorkerURL = FRONTEND_BASE_URL + 'serviceWorker.js';

  try {
    const response = await getAxiosWithToken().get(serviceWorkerURL, {
      cache: false,
    });
    // Success 🎉
    console.log('Success response');

    const serviceWorkerLastModifiedServerDate = moment(
      response.headers['last-modified']
    );
    const serviceWorkerLastModifiedLocalstorageDate = moment(
      localStorage.getItem('SW_LAST_MODIFIED_DATA')
    );
    const isSameServiceWorkerFile = moment(
      serviceWorkerLastModifiedServerDate
    ).isSame(serviceWorkerLastModifiedLocalstorageDate); // true

    if (!isSameServiceWorkerFile) {
      const newVersionContainer = document.getElementById(
        'new-version-container'
      );
      const newVersionText = document.getElementById('new-version-text');
      const refreshBtn = document.getElementById('refresh-btn');
      refreshBtn.innerHTML = i18n.t('newVersionBanner:buttons.refresh');

      refreshBtn.addEventListener('click', (e) => {
        dispatch(startLoader());
        // eslint-disable-next-line no-self-assign
        window.location.href = window.location.href;
        // window.location.reload(true); // on some of the devices it leads to white screen
      });

      newVersionContainer.style.display = 'flex';
      newVersionText.innerHTML = i18n.t('newVersionBanner:newVersionAppMsg');
      localStorage.setItem(
        'SW_LAST_MODIFIED_DATA',
        response.headers['last-modified']
      );
    }
  } catch (error) {
    // Error 😨
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      console.log('error.response.data', error.response.data);
      console.log('error.response.status', error.response.status);
      console.log('error.response.headers', error.response.headers);
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log('error.request', error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      console.log('Error', error.message);
    }
    console.log('error', error);
  }
};
