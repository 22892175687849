import * as i18n from 'i18next';
import { isMobile, isMobileOnly } from 'mobile-device-detect';
import React, { Component, Fragment } from 'react';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import {
  checkIfExpiredStickersExist,
  isAuthenticated,
  showHamburgerMenu,
  hideHamburgerMenu,
  startNavLoader,
  stopNavLoader,
} from '../actions';
import '../styles/app.scss';
import { isExistingSticker } from '../utils/commonUtils';
import {
  COLOR_SCHEME,
  DMT_WEBSITE,
  EMPTY_STRING,
  RELATIVE_PAGE_PATHS,
} from '../utils/constants';
import navigationMethods from '../utils/navigationUtils';
import LogoIcon from './common/svgComponents/LogoIcon';
import LogoSmall from './common/svgComponents/LogoSmall';
import MainMenu from './common/svgComponents/MenuBarIcons/MainMenu';
import NavbarWithNotificationIcon from './common/svgComponents/NavbarWithNotificationIcon';
import { TrackingTimerContext } from '../context/TrackingTimerProvider';
import { TimerVisualizer } from './tracker/shared/TimerVisualizer';

class NavigationComponent extends Component {
  componentDidMount() {
    if (isAuthenticated()) {
      this.props.checkIfExpiredStickersExist();
    }

    let that = this;
    const orientationMode =
      window.outerWidth > window.outerHeight ? 'landscape' : 'portrait';

    if (!isMobile) {
      window.addEventListener(
        'load',
        function (event) {
          that.props.startNavLoader();

          that.styleInPortraitMode();
        },
        true
      );
      window.addEventListener(
        'resize',
        function (event) {
          that.props.startNavLoader();

          that.styleInPortraitMode();
        },
        true
      );
    }

    window.addEventListener(
      'orientationchange',
      function () {
        that.props.startNavLoader();
        if (window.orientation === '90' || window.orientation === '-90') {
          that.styleInLandscapeMode();
        } else {
          that.resetStyles();
        }
      },
      false
    );

    if (isMobile && orientationMode === 'landscape') {
      that.props.startNavLoader();
      that.styleInLandscapeMode();
    }
  }

  componentDidUpdate(prevProps) {
    const orientationMode =
      window.outerWidth > window.outerHeight ? 'landscape' : 'portrait';

    if (!isMobile && this.props.route !== prevProps.route) {
      this.styleInPortraitMode();
    }

    if (isMobile && orientationMode === 'landscape') {
      this.styleInLandscapeMode();
    }
  }

  styleInLandscapeMode() {
    setTimeout(() => {
      const navBackButton = document.querySelector('.bulb-container');
      const navBar = document.querySelector('.header-container');

      if (window.innerHeight < 700) {
        if (navBackButton !== null) {
          navBackButton.style.marginLeft = '-30%';
          navBar.style.width = '48%';
        }
      } else {
        if (navBackButton !== null) {
          navBackButton.style.marginLeft = '-4%';
        }
      }
      this.props.stopNavLoader();
    }, 100);
  }

  styleInPortraitMode() {
    setTimeout(() => {
      const navBackButton = document.querySelector('.bulb-container');

      if (window.innerWidth < 700) {
        if (navBackButton !== null) {
          navBackButton.style.marginLeft = '-30%';
        }
      } else {
        if (navBackButton !== null) {
          navBackButton.style.marginLeft = '-4%';
        }
      }
      this.props.stopNavLoader();
    }, 100);
  }

  resetStyles() {
    const { isHamburgerMenuShown } = this.props;

    this.props.stopNavLoader();
    setTimeout(() => {
      const navBackButton = document.querySelector('.bulb-container');
      const headerContainer = document.querySelector('.header-container');

      if (navBackButton !== null) {
        navBackButton.style = {};
      }

      if (headerContainer !== null) {
        if (isHamburgerMenuShown === 'SHOW') {
          headerContainer.style.width = '48%';
        } else {
          headerContainer.style = {};
        }
      }
    }, 100);
  }

  onMainMenuClick = () => {
    if (this.props.isHamburgerMenuShown === 'SHOW') {
      this.props.startNavLoader();
      this.props.hideHamburgerMenu();
    } else {
      this.props.showHamburgerMenu();
    }
  };

  hamburgerMenu = () => {
    return (
      <div
        className={
          isMobileOnly
            ? 'hamburger-menu-container hamburger-menu-container-mobile'
            : 'hamburger-menu-container hamburger-menu-container-desktop'
        }
        onClick={this.onMainMenuClick}
      >
        <MainMenu width={isMobileOnly ? 31 : 60} height={40} />
      </div>
    )
  };

  navBarNotificationIcon = () => {
    const previousPage = () => {
      navigationMethods.goBack();
    };

    let bulbColor = COLOR_SCHEME.GREEN;
    if (this.props.expiredStickersExist) {
      bulbColor = COLOR_SCHEME.YELLOW;
    }

    if (this.isPageShowingLightbulbInHeaderBar()) {
      return (
        <div className="notification-container">
          <div
            className={
              isMobileOnly
                ? 'bulb-container bulb-container-mobile'
                : 'bulb-container bulb-container-desktop'
            }
          >
            <NavbarWithNotificationIcon
              height={80}
              fill={bulbColor}
              insideNavigationBar={true}
              previousPage={previousPage}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="notification-container nav-grey-background">
          <div
            className={
              isMobileOnly
                ? 'bulb-container bulb-container-mobile'
                : 'bulb-container bulb-container-desktop'
            }
          />
        </div>
      );
    }
  };

  navHeader = () => {
    const { isHamburgerMenuShown } = this.props;

    return (
      <div
        className={this.navHeaderClass()}
        style={isHamburgerMenuShown === 'SHOW' ? { width: '48%' } : {}}
      >
        <div className="noMargin nav-header-text centredText">
          <TrackingTimerContext.Consumer>
            {({ isWorking, activeTracker: tracker, value, additionalInfo }) => {
              if (isWorking) {
                return (
                  <TimerVisualizer
                    tracker={tracker}
                    value={value}
                    addInfo={additionalInfo}
                    hideEmpty
                    enableLink
                    fullInfo
                  />
                );
              }
              return this.getHeaderTitle();
            }}
          </TrackingTimerContext.Consumer>
        </div>
      </div>
    );
  };

  navHeaderClass() {
    let currentRoute = navigationMethods.getRoute();

    if (isMobileOnly) {
      if (
        currentRoute === RELATIVE_PAGE_PATHS.USEFUL_INFO ||
        currentRoute === RELATIVE_PAGE_PATHS.JOURNAL ||
        currentRoute === RELATIVE_PAGE_PATHS.EXPIRED_STICKERS ||
        currentRoute === RELATIVE_PAGE_PATHS.HOME
      ) {
        return 'header-container header-with-bulb-container-mobile';
      } else {
        return 'header-container header-container-mobile';
      }
    } else {
      return 'header-container header-container-desktop';
    }
  }

  navBarLogo = () => {
    return (
      <div className="logo-icon-container">
        {!this.isPageShowingLightbulbInHeaderBar() ? (
          <Col className="logo-icon" style={{ marginTop: 2 }}>
            <a href={DMT_WEBSITE} target="_blank" rel="noreferrer">
              <LogoIcon width={30} height={30} />
            </a>
          </Col>
        ) : (
          <Col className="logo-icon">
            <a href={DMT_WEBSITE} target="_blank" rel="noreferrer">
              <LogoSmall width={84} height={37} />
            </a>
          </Col>
        )}
      </div>
    );
  };

  getHeaderTitle = () => {
    const {
      selectedAddedSticker,
      selectedStickerTemplate,
      selectedTag,
      isHamburgerMenuShown,
      currentSpecialist,
      tracker,
    } = this.props;

    if (isHamburgerMenuShown === 'SHOW') {
      return i18n.t('specialistsScreen:more');
    }

    let selectedTagName = EMPTY_STRING;
    let specialistId = navigationMethods
      .getRoute()
      .includes(RELATIVE_PAGE_PATHS.SPECIALISTS)
      ? currentSpecialist.id
      : '';

    if (selectedAddedSticker && selectedAddedSticker.id) {
      selectedTagName = selectedAddedSticker.stickerTemplate.area.tag.name;
    } else {
      selectedTagName = selectedTag.name
        ? selectedTag.name
        : selectedStickerTemplate.area.tag.name;
    }

    if (tracker) return i18n.t(`trackingScreen:trackers.${tracker.name}`);

    const titles = {
      [RELATIVE_PAGE_PATHS.HOME]: '',
      [RELATIVE_PAGE_PATHS.PROFILE]: i18n.t('profileScreen:title'),
      [RELATIVE_PAGE_PATHS.SELECTED_TAG + selectedTag.id]: i18n.t(
        'goalsAndTagsScreen:tags.name.' + selectedTagName
      ),
      [RELATIVE_PAGE_PATHS.ADD_STICKER + selectedStickerTemplate.id]: i18n.t(
        'goalsAndTagsScreen:tags.name.' + selectedTagName
      ),
      [RELATIVE_PAGE_PATHS.EDIT_STICKER + selectedAddedSticker.id]: i18n.t(
        'goalsAndTagsScreen:tags.name.' + selectedTagName
      ),
      [RELATIVE_PAGE_PATHS.CHANGE_PASSWORD]: i18n.t(
        'changePasswordScreen:title'
      ),
      [RELATIVE_PAGE_PATHS.REQUEST_JOURNAL_DOWNLOAD]: i18n.t(
        'requestJournalDownloadScreen:title'
      ),
      [RELATIVE_PAGE_PATHS.SPECIALISTS + specialistId]: i18n.t(
        'specialistsScreen:title'
      ),
      [RELATIVE_PAGE_PATHS.CONTACTS + specialistId]: i18n.t(
        'specialistsScreen:title'
      ),
      [RELATIVE_PAGE_PATHS.SPECIALISTS]: i18n.t('specialistsScreen:title'),
      [RELATIVE_PAGE_PATHS.ADD_SPECIALISTS]: i18n.t('specialistsScreen:title'),
      [RELATIVE_PAGE_PATHS.CONTACTS]: i18n.t('contactsScreen:title'),
      [RELATIVE_PAGE_PATHS.ADD_CONTACT]: i18n.t('contactsScreen:title'),
      [RELATIVE_PAGE_PATHS.DELETE_PROFILE]: i18n.t('deleteProfileScreen:title'),
      [RELATIVE_PAGE_PATHS.BREAST_FEEDING]: i18n.t('breastfeedingScreen:title'),
      [RELATIVE_PAGE_PATHS.SLEEP]: i18n.t('sleepTrackerScreen:title'),
      [RELATIVE_PAGE_PATHS.CRY]: i18n.t('cryingScreen:title'),
      [RELATIVE_PAGE_PATHS.TRACKING]: i18n.t('trackingScreen:title'),
      [RELATIVE_PAGE_PATHS.SUBSCRIPTION_PLANS]: i18n.t('payments:plans'),
      // [`${RELATIVE_PAGE_PATHS.TRACKING}/${selectedTrackerId}`]: i18n.t(
      //   `trackingScreen:trackers.${selectedTrackerName}`
      // ),
    };
    return titles[navigationMethods.getRoute()];
  };

  isPageShowingLightbulbInHeaderBar() {
    const { isHamburgerMenuShown } = this.props;
    const route = navigationMethods.getRoute();

    return (
      (route === RELATIVE_PAGE_PATHS.HOME ||
        route === RELATIVE_PAGE_PATHS.EXPIRED_STICKERS ||
        route === RELATIVE_PAGE_PATHS.USEFUL_INFO ||
        route === RELATIVE_PAGE_PATHS.JOURNAL ||
        route === RELATIVE_PAGE_PATHS.JOURNAL_READ_ONLY) &&
      (isHamburgerMenuShown === 'HIDE' || isHamburgerMenuShown === 'DISABLED')
    );
  }

  render = () => {
    if (
      this.props.loader ||
      navigationMethods
        .getRoute()
        .includes(RELATIVE_PAGE_PATHS.PHOTO_PREVIEW) ||
      (!isAuthenticated() &&
        navigationMethods.getRoute() !== RELATIVE_PAGE_PATHS.JOURNAL_READ_ONLY)
    ) {
      return <Fragment />;
    } else {
      return (
        <div className="navigation-component-container">
          {this.hamburgerMenu()}
          {this.navBarNotificationIcon()}
          {this.navHeader()}
          {this.navBarLogo()}
          {this.props.navLoader ? (
            <div className="spinner-modal" style={{ zIndex: 10 }}>
              <ReactLoading
                type="spinningBubbles"
                color={COLOR_SCHEME.GREEN}
                height="auto"
                width={150}
              />
            </div>
          ) : null}
        </div>
      );
    }
  };
}

const mapStateToProps = (state) => {
  const isJournalReadOnlyScreen =
    navigationMethods.getRoute() === RELATIVE_PAGE_PATHS.JOURNAL_READ_ONLY;
  return {
    route: navigationMethods.getRoute(),
    loader: state.loader,
    navLoader: state.navLoader,
    expiredStickersExist: isJournalReadOnlyScreen
      ? state.authentication.userData.expiredStickersExist
      : state.expiredStickers.expiredStickersExist,
    selectedAddedSticker: state.selectedSticker.selectedAddedSticker,
    selectedStickerTemplate: isExistingSticker()
      ? state.selectedSticker.selectedAddedSticker.stickerTemplate
      : state.selectedSticker.selectedStickerTemplate,
    selectedTag: state.selectedTag,
    tracker: state.selectedTracker,
    currentSpecialist: state.specialists.specialist,
    isHamburgerMenuShown: isJournalReadOnlyScreen
      ? 'HIDE'
      : state.common.showHamburgerMenu,
  };
};

export default connect(mapStateToProps, {
  checkIfExpiredStickersExist,
  showHamburgerMenu,
  hideHamburgerMenu,
  startNavLoader,
  stopNavLoader,
})(NavigationComponent);
