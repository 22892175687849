import * as i18n from 'i18next';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Col } from 'reactstrap';

import { navigateToHomeScreen } from '../actions';
import { RELATIVE_PAGE_PATHS } from '../utils/constants';
import navigationUtils from '../utils/navigationUtils';
import InternalServerErrorScreen from './errors/InternalServerErrorScreen';
import NotFoundErrorScreen from './errors/NotFoundErrorScreen';
import UnhandledErrorScreen from './errors/UnhandledErrorScreen';
import JournalReadOnlyScreen from './journal/JournalReadOnlyScreen';
import PhotoPreviewScreen from './journal/PhotoPreviewScreen';
import StickerReadOnlyScreen from './journal/StickerReadOnlyScreen';
import NavigationComponent from './NavigationComponent';
import ActivateProfile from './profile/ActivateProfileScreen';
import Login from './profile/LoginScreen';
import RequestPasswordReset from './profile/RequestPasswordResetScreen';
import ResetPassword from './profile/ResetPasswordScreen';
import RegisterScreen from './register/RegisterScreen';
import WelcomeScreen from './WelcomeScreen';
import AdSlot from './common/AdSlot';

class Unauthenticated extends Component {
  render() {
    let screenHeader = i18n.t('welcomeScreen:headerText.welcomeMsg');
    const classes = [
      'flexColumnCentered',
      'fix-padding-safari',
      'pr-0',
      'pl-0',
    ];

    if (!navigationUtils.isCurrentPage(RELATIVE_PAGE_PATHS.HOME)) {
      classes.push('top-banner-container');
    }
    return (
      <Fragment>
        {!navigationUtils.isCurrentPage(RELATIVE_PAGE_PATHS.HOME) ? (
          <Col md={4} className="noPadding">
            <NavigationComponent
              header={screenHeader}
              previousPage={this.props.navigateToHomeScreen}
            />
          </Col>
        ) : null}
        <div>
          <NavigationComponent />
          <div className="ml-3 mr-3">
            <div className="main-section">
              <Col xs={12} className={classes.join(' ')}></Col>
              <Switch>
                <Route
                  exact
                  path={RELATIVE_PAGE_PATHS.HOME}
                  component={WelcomeScreen}
                />
                <Route
                  exact
                  path={RELATIVE_PAGE_PATHS.REGISTER}
                  component={RegisterScreen}
                />
                <Route
                  exact
                  path={RELATIVE_PAGE_PATHS.ACTIVATE_PROFILE}
                  component={ActivateProfile}
                />
                <Route
                  exact
                  path={RELATIVE_PAGE_PATHS.REQUEST_PASSWORD_RESET}
                  component={RequestPasswordReset}
                />
                <Route
                  exact
                  path={RELATIVE_PAGE_PATHS.RESET_PASSWORD}
                  component={ResetPassword}
                />
                <Route
                  exact
                  path={RELATIVE_PAGE_PATHS.LOGIN}
                  component={Login}
                />
                <Route
                  exact
                  path={RELATIVE_PAGE_PATHS.JOURNAL_READ_ONLY}
                  component={JournalReadOnlyScreen}
                />
                <Route
                  exact
                  path={RELATIVE_PAGE_PATHS.STICKER_READ_ONLY + ':id'}
                  component={StickerReadOnlyScreen}
                />
                <Route
                  exact
                  path={RELATIVE_PAGE_PATHS.PHOTO_PREVIEW + ':photo'}
                  component={PhotoPreviewScreen}
                />
                <Route
                  exact
                  path={RELATIVE_PAGE_PATHS.INTERNAL_SERVER_ERROR}
                  component={InternalServerErrorScreen}
                />
                <Route
                  exact
                  path={RELATIVE_PAGE_PATHS.NOT_FOUND}
                  component={NotFoundErrorScreen}
                />
                <Route
                  exact
                  path={RELATIVE_PAGE_PATHS.UNHANDLED_ERROR}
                  component={UnhandledErrorScreen}
                />
                <Route component={NotFoundErrorScreen} />
              </Switch>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, { navigateToHomeScreen })(Unauthenticated);
