import moment from 'moment';
import { DEFAULT_LOCALE_CODE } from './constants';

moment.defineLocale('bg', {
  parentLocale: 'en',
  relativeTime: {
    future: 'след %s',
    past: 'преди %s',
    ss: '%dсек',
    s: '%dсек',
    m: '%dм',
    mm: '%dм',
    h: '%dч',
    hh: '%dч',
    d: '%dд',
    dd: '%dд',
    w: 'седмица',
    ww: '%dседмици',
    M: 'месец',
    MM: '%dмесеца',
    y: 'година',
    yy: '%dг',
  },
});

// Set new thresholds
moment.relativeTimeThreshold('s', 60);
moment.relativeTimeThreshold('ss', 60);
moment.relativeTimeThreshold('m', 60);
moment.relativeTimeThreshold('h', 24);
moment.relativeTimeThreshold('d', 7);
moment.relativeTimeThreshold('w', 4);
moment.relativeTimeThreshold('M', 12);

moment.locale(DEFAULT_LOCALE_CODE);

export default moment;
