import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generateCalendarRow,
  generateTableHeader,
  generateTopLayerTable,
  getRecordPositionStyle,
} from '../helper/sleepUtils';
import { IHoverSleep } from '../helper/ISleepAnalytics.interface';
import { ITrackerRecord } from '../../../interfaces/ITrackerRecord.interface';
import moment from 'moment';
import { DATE_FORMAT } from '../../../utils/constants';

interface ISleepCalendar {
  period: {
    startDate: Date;
    endDate: Date;
  };
  data: ITrackerRecord[][];
}

const SleepCalendar: React.FC<ISleepCalendar> = ({ period, data }) => {
  const { t } = useTranslation();
  const [tableGrid, setTableGrid] = useState<JSX.Element[]>();
  const [recordElements, setRecordElements] = useState<JSX.Element[]>();
  const [topLayerTable, setTopLayerTable] = useState<JSX.Element>();
  const [activeHoveredRecord, setActiveHoveredRecord] =
    useState<IHoverSleep | null>();

  const handleRecordHover = (divInfo: IHoverSleep | null) => {
    setActiveHoveredRecord(divInfo);
  };

  useEffect(() => {
    // Create base calendar layout
    const datesArray = getDatesBetween(period.startDate, period.endDate);
    const tableLayout = datesArray.map((date, i) =>
      generateCalendarRow(date, i)
    );
    setTableGrid(tableLayout);

    // Create record cells
    const dateMap: Map<String, number> = new Map();
    datesArray.forEach((date, index) => {
      const momentDate = moment(date);
      const dateFormat = momentDate.format(DATE_FORMAT);
      dateMap.set(dateFormat, index);
    });

    const recordDivs = data
      .map((recordsForDay) =>
        recordsForDay
          .map((record) => {
            const recordDate = moment(new Date(record.date));
            const recordRowIndex = dateMap.get(recordDate.format(DATE_FORMAT));
            const generatedRecordDivs = getRecordPositionStyle(
              record,
              recordRowIndex!,
              handleRecordHover
            );

            const nextDay = recordDate.add(1, 'days').format(DATE_FORMAT);
            const isLastRow = !Boolean(dateMap.get(nextDay));

            return isLastRow && generatedRecordDivs.length > 1
              ? generatedRecordDivs[0]
              : generatedRecordDivs;
          })
          .flat()
      )
      .flat();

    setRecordElements(recordDivs);

    // Create top layout grid
    setTopLayerTable(generateTopLayerTable(datesArray.length));
  }, [data]);

  return (
    <div
      style={{
        position: 'relative',
        width: 'fit-content',
        background: 'rgba(235, 246, 255, 1)',
        borderRadius: '12px',
        padding: '12px 8px',
      }}
    >
      <h5
        style={{
          fontWeight: 700,
          fontSize: '15px',
          lineHeight: '27px',
          fontFamily: 'Nunito, sans-serif',
          margin: 0,
        }}
      >
        {t('sleepAnalytics:sleepCalendar.sleepCalendar')}
      </h5>
      <div
        id="sleep-calendar"
        style={{
          position: 'relative',
          width: 'fit-content',
        }}
      >
        {generateTableHeader()}
        {tableGrid}
        {recordElements}
        {topLayerTable}

        {activeHoveredRecord?.recordId && (
          <div
            id="sleep-hover-wrapper"
            style={{
              top: `${activeHoveredRecord.topPosition}`,
              left: `${activeHoveredRecord.leftPosition}`,
            }}
          >
            <div className="sleep-hover-container">
              <p id="sleep-hover-date">{activeHoveredRecord.recordDate}</p>
              <p className="sleep-hover-text">
                {activeHoveredRecord?.recordNote}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function getDatesBetween(startDate: Date, endDate: Date) {
  const dates = [];
  let currentDate = new Date(startDate.getTime());

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
}

export default SleepCalendar;
