import * as i18n from 'i18next';
import { defaultServerValidationErrorCallback } from '../utils/commonUtils';
import { RELATIVE_PAGE_PATHS } from '../utils/constants';
import navigationMethods from '../utils/navigationUtils';
import { getAxiosWithToken } from '../utils/webApi';
import {
    LOAD_SPECIALIST,
    LOAD_SPECIALISTS,
    SET_JOURNAL_TOKEN_STATUS_SUCCESS
} from './actionTypes';
import {
    closeModal, showSuccessModal,
    startLoader,
    stopLoader
} from './commonActions';

export const addSpecialist = (data) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().post('professional/add', data);

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        navigationMethods.navigate(RELATIVE_PAGE_PATHS.SPECIALISTS);
        dispatch(
            showSuccessModal(
                i18n.t('specialistsScreen:successfulAddedSpecialist')
            )
        );
    }
};

export const getSpecialists = () => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().get('professional/get-all');

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        dispatch({ type: LOAD_SPECIALISTS, payload: returnedData.data });
    }
};

export const getSpecialist = (id) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken(
        null,
        defaultServerValidationErrorCallback
    ).post(`professional/${id}`, { id });

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        dispatch({ type: LOAD_SPECIALIST, payload: returnedData.data });
        return returnedData.data;
    }
};

export const updateSpecialist = (id, data) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().put(`professional/${id}`, {
        id,
        ...data,
    });

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        navigationMethods.navigate(RELATIVE_PAGE_PATHS.SPECIALISTS);
        dispatch(
            showSuccessModal(
                i18n.t('specialistsScreen:successfulUpdatedSpecialist')
            )
        );
    }
};

export const deleteSpecialist = (id) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().delete(`professional/${id}`, {
        data: { id },
    });

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        navigationMethods.navigate(RELATIVE_PAGE_PATHS.SPECIALISTS);
    }
};

export const shareJournalThroughEmail = (email) => async (dispatch) => {
    dispatch(closeModal());
    dispatch(startLoader());

    const response = await getAxiosWithToken().post(
        'journal/send-email-with-journal-url-specialist',
        { email }
    );

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        dispatch(
            showSuccessModal(
                i18n.t('specialistsScreen:successfulSharedJournal')
            )
        );
    }
};

export const handleStopSharingJournalClick = (id) => async (dispatch) => {
    dispatch(startLoader());

    try {
        const response = await getAxiosWithToken().patch(`professional/not-sharing-journal/${id}`, {
            isJournalShared: false,
        });

        if (response.status === 200) {
            dispatch({
                type: SET_JOURNAL_TOKEN_STATUS_SUCCESS,
                payload: { id, isJournalShared: false },
            });
        } else {
            console.error('Error updating journal status:', response);
            throw new Error(`Failed to update journal status: ${response.status}`);
        }
    } catch (error) {
        console.error('Error updating journal status:', error.message);
    } finally {
        dispatch(
            showSuccessModal(
                i18n.t('specialistsScreen:successfulStoppedSharingJournal')
            )
        );
        dispatch(stopLoader());
    }
};

export const handleShareJournalClick = (id) => async (dispatch) => {
    dispatch(startLoader());

    try {
        const response = await getAxiosWithToken().patch(`professional/sharing-journal/${id}`, {
            isJournalShared: true,
        });

        if (response.status === 200) {
            dispatch({
                type: SET_JOURNAL_TOKEN_STATUS_SUCCESS,
                payload: { id, isJournalShared: true },
            });
        } else {
            console.error('Error updating journal status:', response);
            throw new Error(`Failed to update journal status: ${response.status}`);
        }
    } catch (error) {
        console.error('Error updating journal status:', error.message);
    } finally {
        dispatch(stopLoader());
    }
};

export const setJournalTokenActiveStatusToFalse = (email) => async (dispatch) => {
    dispatch(startLoader());

    try {
        const response = await getAxiosWithToken().patch(`SharedJournalTokens/token-is-not-active/${email}`, {
            isActive: false,
        });

        if (response.status === 200) {
            dispatch({
                type: SET_JOURNAL_TOKEN_STATUS_SUCCESS,
                payload: { email, isActive: false },
            });
        } else {
            console.error('Error updating token status:', response);
            throw new Error(`Failed to update token status: ${response.status}`);
        }
    } catch (error) {
        console.error('Error updating token status:', error.message);
    } finally { 
        dispatch(stopLoader());
    }
};

export const setJournalTokenActiveStatusToTrue = (id) => async (dispatch) => {
    dispatch(startLoader());

    try {
        const response = await getAxiosWithToken().patch(`SharedJournalTokens/token-is-active/${id}`, {
            isActive: true,
        });

        if (response.status === 200) {
            dispatch({
                type: SET_JOURNAL_TOKEN_STATUS_SUCCESS,
                payload: { id, isActive: true },
            });
        } else {
            console.error('Error updating token status:', response);
            throw new Error(`Failed to update token status: ${response.status}`);
        }
    } catch (error) {
        console.error('Error updating token status:', error.message);
    } finally {
        dispatch(stopLoader());
    }
};