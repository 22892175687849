import React, { Component } from 'react';
import Read from './svgComponents/MenuBarIcons/Read';
import Add from './svgComponents/MenuBarIcons/Add';
import DetectTime from './svgComponents/MenuBarIcons/DetectTime';
import Diary from './svgComponents/MenuBarIcons/Diary';
import Analyses from './svgComponents/MenuBarIcons/Analyses';
import { connect } from 'react-redux';
import { hideHamburgerMenu, startNavLoader } from '../../actions';
import { Col } from 'reactstrap';
import navigationMethods from '../../utils/navigationUtils';
import { RELATIVE_PAGE_PATHS } from '../../utils/constants';
import * as i18n from 'i18next';

class FooterNavigationBar extends Component {
  navigateTo = (path) => {
    navigationMethods.navigate(path);
    this.props.hideHamburgerMenu();
  };

  render() {
    const footerClass = this.props.className
      ? 'flexContainer footerNavBar ' + this.props.className
      : 'flexContainer footerNavBar';

    return (
      <Col className={footerClass}>
        <div
          onClick={() => {
            this.navigateTo(RELATIVE_PAGE_PATHS.USEFUL_INFO);
          }}
          style={{
            padding: '0px 12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: 12,
          }}
        >
          <Read width={24} height={24} />
          <span>{i18n.t('footerNavigationBar:read')}</span>
        </div>
        <div
          onClick={() => {
            this.navigateTo(RELATIVE_PAGE_PATHS.HOME);
          }}
          style={{
            padding: '0px 12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: 12,
          }}
        >
          <Add width={24} height={24} />
          <span>{i18n.t('footerNavigationBar:infostickers')}</span>
        </div>

        <div
          onClick={() => {
            this.navigateTo(RELATIVE_PAGE_PATHS.JOURNAL);
          }}
          style={{
            padding: '0px 12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: 12,
          }}
        >
          <Diary width={24} height={24} />
          <span>{i18n.t('footerNavigationBar:journal')}</span>
        </div>
        <div
          onClick={() => {
            this.navigateTo(RELATIVE_PAGE_PATHS.TRACKING);
          }}
          style={{
            padding: '0px 12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: 12,
          }}
        >
          <DetectTime width={24} height={24} />
          <span>{i18n.t('footerNavigationBar:detectTime')}</span>
        </div>

        <div
          onClick={() => {
            this.navigateTo(RELATIVE_PAGE_PATHS.ANALYTICS);
          }}
          style={{
            padding: '0px 12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: 12,
          }}
        >
          <Analyses width={24} height={24.3} />
          <span>{i18n.t('footerNavigationBar:analyses')}</span>
        </div>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  return { isHamburgerMenuShown: state.common.showHamburgerMenu };
};

export default connect(mapStateToProps, {
  hideHamburgerMenu,
  startNavLoader,
})(FooterNavigationBar);
