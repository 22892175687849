import { useTranslation } from 'react-i18next';

const GrowthFooter = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div id="who-data">
        <p style={{ marginBottom: 0 }}>{t('growthCurves:footer.WHO-info')}</p>
        <p>
          <a
            style={{ color: '#4B4b4B' }}
            target="_blank"
            href="https://www.who.int/tools/child-growth-standards/standards"
          >
            https://www.who.int/tools/child-growth-standards/standards
          </a>
        </p>
      </div>
      <div id="growth-footer">
        <div id="view-more">
          <p>{t('growthCurves:footer.is-it-growing')}</p>
          <a
            target="_blank"
            href="https://www.namama.bg/category/rastej/rast-teglo-izmervaniia/"
          >
            {t('growthCurves:footer.view-more')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default GrowthFooter;
